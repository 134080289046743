import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { SnackbarService } from 'app/shared/services/snackbar.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private _snackbarService: SnackbarService,
    private _authService: AuthService,
    private _router: Router
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((httpResponseError: HttpErrorResponse) => {
        // parse error
        const parsedError = this._parseError(httpResponseError);

        switch (httpResponseError.status) {
          case 401: {
            /** 401 UNAUTHORIZED */
            this._authService.logoutRedirect();
            break;
          }
          case 403: {
            /** 403 FORBIDDEN */
            // display a snackbar when a 403 is encountered.
            this._snackbarService.errorWithHandler(
              httpResponseError.error,
              'Insufficient Access',
              'Some functionality on this page may not work as expected'
            );

            // if 403 error occurs on a resolve and no parent/previous routing has occurred yet
            // send user to app home
            if (!this._router.routerState.snapshot.url) {
              this._sendHome();
            }

            break;
          }

          case 500: {
            // display a snackbar when a 500 error is encountered.
            this._snackbarService.errorWithHandler(httpResponseError.error, 'Error');
            // if 500 error occurs on a resolve and no parent/previous routing has occurred yet
            // send user to app home
            if (!this._router.routerState.snapshot.url) {
              this._sendHome();
            }
            break;
          }
        }

        return throwError(() => parsedError);
      })
    );
  }

  private _parseError(httpResponseError: HttpErrorResponse) {
    // Use Angular error message as default
    let message: string = httpResponseError.message;

    try {
      // API will return error in a couple different ways :(

      const error = JSON.parse(httpResponseError.error);
      if (typeof error?.status === 'object') {
        // "{"content":{},"status":{"code":0,"message":"Some error message"}}"
        message = error.status.message;
      } else if (typeof error?.err === 'string') {
        // "{"err":"Error: Some error message"}"
        message = error.err;
      }
      return error;
    } catch (e) {}

    return {
      status: httpResponseError.status,
      message
    };
  }

  private _sendHome() {
    this._router.navigate(['/']);
  }
}

<span matSnackBarLabel class="flex flex-col gap-4">
  <div class="flex flex-col" *ngFor="let messageObj of messageObjs">
    <div>{{ messageObj.message }}</div>
    <ul class="list-disc ml-6">
      <li *ngFor="let item of messageObj.messageList">{{ item }}</li>
    </ul>
  </div>
</span>
<span matSnackBarActions>
  <button mat-button matSnackBarAction (click)="snackBarRef.dismissWithAction()">Dismiss</button>
</span>

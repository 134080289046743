import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { IFamily } from 'app/library/becks-family';
import * as ProductsActions from '../actions/products.actions';

interface FamiliesState extends EntityState<IFamily> {}

export interface ProductsState {
  model: {
    families: FamiliesState;
  };
  viewModel: {
    familiesLoading: boolean;
    familiesLoaded: boolean;
    familiesLoadErred: boolean;
  };
}

export const familiesAdapter = createEntityAdapter<IFamily>({
  selectId: (family: IFamily) => family.code
});

const initialState: ProductsState = {
  model: {
    families: familiesAdapter.getInitialState()
  },
  viewModel: {
    familiesLoading: false,
    familiesLoaded: false,
    familiesLoadErred: false
  }
};

export const productsStateKey = 'products';
export const productsReducer = createReducer(
  initialState,

  on(ProductsActions.loadProductFamilies, (state) => ({
    ...state,
    viewModel: {
      ...state.viewModel,
      familiesLoading: true,
      familiesLoaded: false,
      familiesLoadErred: false
    }
  })),
  on(ProductsActions.loadProductFamiliesFailure, (state, { error }) => ({
    ...state,
    viewModel: {
      ...state.viewModel,
      familiesLoading: false,
      familiesLoaded: false,
      familiesLoadErred: true
    }
  })),
  on(ProductsActions.loadProductFamiliesSuccess, (state, { data }) => ({
    ...state,
    model: {
      ...state.model,
      families: familiesAdapter.addMany(data, state.model.families)
    },
    viewModel: {
      ...state.viewModel,
      familiesLoading: false,
      familiesLoaded: true,
      familiesLoadErred: false
    }
  }))
);

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import { AuthService } from 'app/core/auth/auth.service';
import { getUserLoaded, UserActions } from 'app/store';
import { Observable } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';

export const userGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const store = inject(Store);

  return authService.isAuthenticated$.pipe(
    // only pass when user is authenticated
    filter((isAuthenticated) => isAuthenticated),
    // perform necessary loads
    switchMap(() => checkStore(store))
  );
};

const checkStore = (store: Store): Observable<boolean> => {
  return store.select(getUserLoaded).pipe(
    tap((loaded) => {
      if (!loaded) {
        store.dispatch(UserActions.loadUser());
      }
    }),
    filter((loaded) => loaded),
    take(1)
  );
};

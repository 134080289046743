import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { ICustomer } from '@becksdevteam/becks-invoicing';

import * as SalesLookupActions from '../actions/sales-lookup.actions';

interface DealersState extends EntityState<ICustomer> {}

export interface SalesLookupState {
  model: {
    dealers: DealersState;
  };
  viewModel: {
    dealersLoading: boolean;
    dealersLoaded: boolean;
    dealersLoadErred: boolean;
  };
}

export const dealersAdapter = createEntityAdapter<ICustomer>({
  selectId: (dealer: ICustomer) => dealer.ID
});

const initialState: SalesLookupState = {
  model: {
    dealers: dealersAdapter.getInitialState()
  },
  viewModel: {
    dealersLoading: false,
    dealersLoaded: false,
    dealersLoadErred: false
  }
};

export const salesLookupStateKey = 'salesLookup';
export const salesLookupReducer = createReducer(
  initialState,

  on(SalesLookupActions.loadDealers, (state) => ({
    ...state,
    viewModel: {
      ...state.viewModel,
      dealersLoading: true,
      dealersLoaded: false,
      dealersLoadErred: false
    }
  })),
  on(SalesLookupActions.loadDealersFailure, (state, { error }) => ({
    ...state,
    viewModel: {
      ...state.viewModel,
      dealersLoading: false,
      dealersLoaded: false,
      dealersLoadErred: true
    }
  })),
  on(SalesLookupActions.loadDealersSuccess, (state, { dealers }) => ({
    ...state,
    model: {
      ...state.model,
      dealers: dealersAdapter.addMany(dealers, state.model.dealers)
    },
    viewModel: {
      ...state.viewModel,
      dealersLoading: false,
      dealersLoaded: true,
      dealersLoadErred: false
    }
  }))
);

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ISalesYear } from '../../shared/models/sales-year.interface';

import { greaterThanEqual, greaterThanZero, lessThanZero } from 'app/shared/helpers/number.helper';
import { SalesYearsState, salesYearsStateKey } from 'app/store/reducers/sales-years.reducer';
import { getRouterQueryParams } from 'app/store/selectors/router.selector';

export const getSalesYearsState = createFeatureSelector<SalesYearsState>(salesYearsStateKey);

export const getSalesYears = createSelector(getSalesYearsState, (state: SalesYearsState) => state.salesYears);
export const getSalesYearsLoaded = createSelector(getSalesYearsState, (state: SalesYearsState) => state.salesYearsLoaded);
export const getSalesYearsLoading = createSelector(getSalesYearsState, (state: SalesYearsState) => state.salesYearsLoading);

export const selectSelectedSalesYear = createSelector(getSalesYearsState, (state: SalesYearsState) => state.selectedSalesYear);
export const selectSalesYearsChanging = createSelector(getSalesYearsState, (state: SalesYearsState) => state.salesYearChanging);
export const selectSelectedSalesYearIsCurrent = createSelector(selectSelectedSalesYear, (selectedSalesYear) => {
  return Boolean(selectedSalesYear?.currentYearFlag);
});

export const getCurrentSalesYear = createSelector(getSalesYears, (salesYears) => {
  const currentSalesYear = salesYears.find((year: ISalesYear) => {
    return year.currentYearFlag;
  });
  return currentSalesYear;
});
export const getPreviousSalesYear = createSelector(getSalesYears, (salesYears) => {
  const lastYear = salesYears.find((year: ISalesYear) => {
    return year.yearsFromCurrentYear === -1;
  });
  return lastYear;
});
export const getNextSalesYear = createSelector(getSalesYears, (salesYears) => {
  const nextYear = salesYears.find((year: ISalesYear) => {
    return year.yearsFromCurrentYear === 1;
  });
  return nextYear;
});

export const getSalesYearFromSalesYearNoDash = (salesYearNoDash: string) => {
  return createSelector(getSalesYears, (salesYears) => {
    return salesYears.find((sy) => sy.salesYearNoDash === salesYearNoDash);
  });
};

export const selectSalesYearQueryParamObject = createSelector(getRouterQueryParams, (params) => {
  return params?.yearCode ? { salesYear: params.yearCode as string } : undefined;
});

export const selectYearCodeQueryParamObject = createSelector(getRouterQueryParams, (params) => {
  return params?.yearCode ? { yearCode: params.yearCode as string } : undefined;
});
export const selectYearCodeQueryParam = createSelector(getRouterQueryParams, (params): string | undefined => {
  return params?.yearCode;
});

export const selectSalesYearFromRoute = createSelector(selectYearCodeQueryParam, getSalesYears, (yearCodeParam, salesYears) => {
  return salesYears.find((salesYear) => salesYear.salesYearNoDash === yearCodeParam);
});

export const selectSalesYearFromRouteIsValidSalesYear = createSelector(selectSalesYearFromRoute, (salesYear) => {
  return Boolean(salesYear);
});

export const selectSalesYearFromRouteIsCurrentSalesYear = createSelector(selectSalesYearFromRoute, (salesYear) => {
  return Boolean(salesYear?.currentYearFlag);
});

export const selectSalesYearFromRouteNotCurrentSalesYear = createSelector(
  selectSalesYearFromRouteIsCurrentSalesYear,
  (isCurrentSalesYear) => {
    return isCurrentSalesYear === false;
  }
);

export const selectSalesYearFromRouteIsLastSalesYear = createSelector(selectSalesYearFromRoute, (salesYear) => {
  return salesYear?.yearsFromCurrentYear === -1;
});

export const selectIsSalesYearFromRouteInPast = createSelector(selectSalesYearFromRoute, (salesYear) =>
  lessThanZero(salesYear?.yearsFromCurrentYear)
);

export const selectIsSalesYearFromRouteInFuture = createSelector(selectSalesYearFromRoute, (salesYear) =>
  greaterThanZero(salesYear?.yearsFromCurrentYear)
);

export const selectIsSalesYearFromRouteCurrentOrFuture = createSelector(selectSalesYearFromRoute, (salesYear) =>
  greaterThanEqual(salesYear?.yearsFromCurrentYear, 0)
);

export const getCurrentSalesYearDateRange = createSelector(getCurrentSalesYear, (currentSalesYear) => {
  if (currentSalesYear === undefined) {
    return null;
  }

  const { startYear, endYear } = currentSalesYear;
  return { startDate: new Date(`${startYear}-08-02`), endDate: new Date(`${endYear}-08-01`) };
});

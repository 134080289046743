<div [ngClass]="{ 'multiple-becks-family-toggle': multipleFamilySelect }" class="becks-family-toggle">
  <ng-container *ngIf="!multipleFamilySelect">
    <mat-icon
      *ngFor="let group of familyGroups"
      [ngClass]="{ selected: group.code === selectedFamilyGroup?.code, clickable: !isReadOnly }"
      [matTooltip]="group.desc"
      class="becks-family-toggle__option relative"
    >
      <lib-becks-svg [src]="group.iconPath" (click)="handleFamilyGroupSelected(group, $event)"></lib-becks-svg>
    </mat-icon>
  </ng-container>
  <ng-container *ngIf="multipleFamilySelect">
    <mat-icon
      *ngFor="let group of familyGroups; let i = index"
      [ngClass]="{ selected: group.code && selectedFamilyCodes?.includes(group.code), clickable: !isReadOnly }"
      [matTooltip]="group.desc"
      class="becks-family-toggle__option"
    >
      <lib-becks-svg [src]="group.iconPath" (click)="handleFamilyGroupSelected(group, $event)"></lib-becks-svg>
    </mat-icon>
  </ng-container>
</div>

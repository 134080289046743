import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/button-toggle';
import { provideNativeDateAdapter } from '@angular/material/core';
import { HammerGestureConfig, HammerModule, Title } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { BecksNativeDateTimeModule } from '@becksdevteam/angular-common/datetime-picker';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { Angulartics2Module } from 'angulartics2';
import { StartupService } from 'app/core/services/startup.service';
import { CoreModule } from '../app/core/core.module';
import { CustomGestureConfig } from '../app/shared/classes/custom-gesture-config.class';
import { SharedModule } from '../app/shared/shared.module';
import { windowProvider, windowToken } from '../app/shared/window-token';
import { CustomRouteSerializer, effects, reducers } from '../app/store';
import { APP_ROUTES } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(CoreModule, SharedModule.forRoot(), Angulartics2Module.forRoot(), BecksNativeDateTimeModule, HammerModule),
    provideRouter(APP_ROUTES),
    provideStore(reducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    provideEffects(effects),
    provideStoreDevtools({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      connectInZone: true // If set to true, the connection is established within the Angular zone
    }),
    provideRouterStore({
      serializer: CustomRouteSerializer
    }),
    Title,
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} },
    { provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS, useValue: { hideSingleSelectionIndicator: true } },
    { provide: windowToken, useFactory: windowProvider },
    {
      provide: HammerGestureConfig,
      useClass: CustomGestureConfig
    },
    provideNativeDateAdapter(),
    provideAnimations(),
    {
      provide: APP_INITIALIZER,
      useFactory: (startupService: StartupService) => () => startupService.loadSystemStatus(),
      deps: [StartupService],
      multi: true
    }
  ]
};

// Purpose: Service for handling socket.io connections.
import { Inject, Injectable } from '@angular/core';

import { SocketIoConfig } from './socket-io.model';
import { SOCKET_CONFIG_TOKEN } from './socket-io.token';

import { AuthService } from 'app/core/auth/auth.service';
import { SocketIo } from './socket-io.class';

@Injectable()
export class SocketIoService {
  constructor(
    @Inject(SOCKET_CONFIG_TOKEN) private readonly config: SocketIoConfig,
    private authService: AuthService
  ) {}

  /** Creates a new socket connection */
  createSocket() {
    return new SocketIo({
      ...this.config,
      options: {
        ...this.config.options,
        auth: (cb) => {
          this.authService.getAccessToken().then((token) => cb({ token, type: 'jwt' }));
        }
      }
    });
  }
}

import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import { BehaviorSubject, Observable } from 'rxjs';

import { ICustomer } from '@becksdevteam/becks-invoicing';
import { IToolboxRequestCategory, IToolboxRequestType } from '../../toolbox/shared/models/account.interface';
import { CommonDatabaseObject, IDealerSalesRepType, IState } from '../models/options.interface';
import { IDsm } from '../models/sales-people.interface';

import { ICashDiscount, ICommitmentRewards, IDiscount } from '@becksdevteam/becks-invoicing';
import { SalesYearQueryParamObj } from 'app/business/models/business-query-params.interface';
import { buildQueryParams } from 'app/shared/helpers/query-params.helper';
import { ApiService } from '../../shared/services/api.service';

@Injectable()
export class SalesLookupService {
  private _counties: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private _dealers: BehaviorSubject<ICustomer[]> = new BehaviorSubject<ICustomer[]>([]);
  private _dealersUnsecured: BehaviorSubject<ICustomer[]> = new BehaviorSubject<ICustomer[]>([]);
  private _districts: BehaviorSubject<IDsm[]> = new BehaviorSubject<IDsm[]>([]);
  private _districtsUnsecured: BehaviorSubject<IDsm[]> = new BehaviorSubject<IDsm[]>([]);
  private _phoneTypes: BehaviorSubject<CommonDatabaseObject[]> = new BehaviorSubject<CommonDatabaseObject[]>([]);
  private _states: BehaviorSubject<IState[]> = new BehaviorSubject<IState[]>([]);
  private _toolboxTypes: BehaviorSubject<IToolboxRequestType[]> = new BehaviorSubject<IToolboxRequestType[]>([]);
  private _toolboxCategories: BehaviorSubject<IToolboxRequestCategory[]> = new BehaviorSubject<IToolboxRequestCategory[]>([]);
  private _dealerSalesRepTypes: BehaviorSubject<IDealerSalesRepType[]> = new BehaviorSubject<IDealerSalesRepType[]>([]);

  public counties$: Observable<string[]> = this._counties.asObservable();
  public dealers$: Observable<ICustomer[]> = this._dealers.asObservable();
  public dealersUnsecured$: Observable<ICustomer[]> = this._dealers.asObservable();
  public districts$: Observable<IDsm[]> = this._districts.asObservable();
  public districtsUnsecured$: Observable<IDsm[]> = this._districts.asObservable();
  public phoneTypes$: Observable<CommonDatabaseObject[]> = this._phoneTypes.asObservable();
  public states$: Observable<IState[]> = this._states.asObservable();
  public toolboxTypes$: Observable<IToolboxRequestType[]> = this._toolboxTypes.asObservable();
  public toolboxCategories$: Observable<IToolboxRequestCategory[]> = this._toolboxCategories.asObservable();
  public dealerSalesRepTypes$: Observable<IDealerSalesRepType[]> = this._dealerSalesRepTypes.asObservable();

  constructor(private _apiService: ApiService) {}

  public getCounties(): Observable<string[]> {
    return this.counties$;
  }

  public fetchCounties(state: string): Observable<string[]> {
    return this._apiService.get(`/common/counties/${state}`).pipe(
      tap((counties: string[]) => {
        this._counties.next(counties);
      })
    );
  }

  public getDealers(): Observable<ICustomer[]> {
    return this.dealers$;
  }

  public fetchDealers(): Observable<ICustomer[]> {
    return this._apiService.get('/sales/dealers/secure').pipe(
      tap((dealers: ICustomer[]) => {
        this._dealers.next(dealers);
      })
    );
  }
  public fetchDealersUnsecured(): Observable<ICustomer[]> {
    return this._apiService.get('/sales/dealers').pipe(
      tap((dealers: ICustomer[]) => {
        this._dealersUnsecured.next(dealers);
      })
    );
  }

  public getDistricts(): Observable<IDsm[]> {
    return this.districts$;
  }

  public fetchDistricts(): Observable<IDsm[]> {
    return this._apiService.get('/sales/districts/secure').pipe(
      tap((districts: IDsm[]) => {
        this._districts.next(districts);
      })
    );
  }

  public fetchDistrictsUnsecure(): Observable<IDsm[]> {
    return this._apiService.get('/sales/districts').pipe(
      tap((districts: IDsm[]) => {
        this._districtsUnsecured.next(districts);
      })
    );
  }

  public fetchPhoneTypes() {
    return this._apiService
      .get(`/customerMaster/phoneTypes`)
      .pipe(tap((phoneTypes: CommonDatabaseObject[]) => this._phoneTypes.next(phoneTypes)));
  }

  public getStates(): Observable<IState[]> {
    return this.states$;
  }

  public fetchStates(): Observable<IState[]> {
    return this._apiService.get(`/general/states`).pipe(
      tap((states: IState[]) => {
        this._states.next(states);
      })
    );
  }

  public fetchToolboxRequestTypes(): Observable<IToolboxRequestType[]> {
    return this._apiService.get(`/toolbox/requestTypes`).pipe(
      tap((types: IToolboxRequestType[]) => {
        this._toolboxTypes.next(types);
      })
    );
  }

  public getToolboxRequestTypes(): Observable<IToolboxRequestType[]> {
    return this.toolboxTypes$;
  }

  public fetchToolboxRequestCategories(type: any): Observable<any[]> {
    return this._apiService.get(`/toolbox/requestCategories/${type.ID}`).pipe(
      tap((cats: IToolboxRequestCategory[]) => {
        this._toolboxCategories.next(cats);
      })
    );
  }

  public getToolboxRequestCategories(): Observable<IToolboxRequestCategory[]> {
    return this.toolboxCategories$;
  }

  public fetchDealerSalesRepTypes(): Observable<IDealerSalesRepType[]> {
    return this._apiService.get(`/common/dealerSalesRepTypes`).pipe(
      tap((types: IDealerSalesRepType[]) => {
        this._dealerSalesRepTypes.next(types);
      })
    );
  }

  public fetchDiscounts(salesYearCode?: string): Observable<IDiscount[]> {
    return this._apiService.get(`/discounts?salesYear=${salesYearCode}`);
  }

  public fetchCashDiscounts(salesYearQueryParamObj: SalesYearQueryParamObj): Observable<ICashDiscount> {
    const queryParams = { ...salesYearQueryParamObj };
    const queryString = buildQueryParams(queryParams);
    return this._apiService.get(`/discounts/cashDiscounts?${queryString}`);
  }

  public fetchCommitmentLevelDefinitions(salesYearQueryParamObj: SalesYearQueryParamObj): Observable<ICommitmentRewards> {
    const queryParams = { ...salesYearQueryParamObj };
    const queryString = buildQueryParams(queryParams);
    return this._apiService.get(`/discounts/commitmentRewards?${queryString}`);
  }
}

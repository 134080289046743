import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { IState } from 'app/shared/models/options.interface';
import { forkJoin, Observable } from 'rxjs';
import { SalesLookupService } from '../../../shared/services/sales-lookup.service';

export const layoutResolver: ResolveFn<Observable<IState[][]>> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  salesLookupService: SalesLookupService = inject(SalesLookupService)
) => {
  const runFetches = [salesLookupService.fetchStates()];

  return forkJoin(runFetches);
};

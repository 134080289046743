import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ProgressSpinnerService {
  private _loading: Subject<boolean> = new Subject<boolean>();

  isLoading = this._loading.asObservable();

  constructor() {}

  start() {
    this._loading.next(true);
  }

  end() {
    this._loading.next(false);
  }
}

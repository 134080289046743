import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { SnackbarMessageWithListComponent } from 'app/shared/components/snackbar/snackbar-message-with-list/snackbar-message-with-list.component';
import { SnackBarListMessage } from 'app/shared/models/snackbar.interface';

@Injectable()
export class SnackbarService {
  constructor(
    public snackBar: MatSnackBar,
    private _titleCasePipe: TitleCasePipe
  ) {}

  private _openSnack: MatSnackBarRef<SimpleSnackBar | SnackbarMessageWithListComponent> | undefined;

  success(message: string) {
    this.snackBar.open(message, undefined, {
      duration: 3000,
      panelClass: ['success']
    });
  }

  error(message: string) {
    this._openSnack = this.snackBar.open(message, 'DISMISS', {
      panelClass: ['error']
    });
    this._openSnack.onAction().subscribe(() => {
      this._openSnack?.dismiss();
    });
  }

  // appendNewMessageObjs only works if there is no possibility of the user dismissing the first snackbar message before the second is called
  errorWithList(config: { messageObjs: SnackBarListMessage[]; appendNewMessageObjs: boolean }) {
    const messageObjs: SnackBarListMessage[] = config.messageObjs;
    const currentMessageObjs: SnackBarListMessage[] = this._openSnack?.containerInstance.snackBarConfig.data.messageObjs;

    if (config.appendNewMessageObjs && currentMessageObjs) {
      // Append existing deduped messageObjs
      messageObjs.push(
        ...currentMessageObjs.filter(
          (currentMessageObjs) => !messageObjs.find((newMessage) => newMessage.message === currentMessageObjs.message)
        )
      );
    }

    this._openSnack = this.snackBar.openFromComponent(SnackbarMessageWithListComponent, {
      data: { messageObjs },
      panelClass: ['error']
    });
  }

  errorWithHandler(rawError: any, errorPrefix: string, optGenericMessage?: string) {
    // ex. error with unhandled error type:   snackbarService.errorWithHandler(err, 'Error Uploading Document', 'Please Try Again') =>  'Error Uploading Document: Please Try Again'
    let displayMessage: string = '';

    if (rawError.type === 'handled') {
      displayMessage = `${errorPrefix}: ${this._titleCasePipe.transform(rawError.details?.length ? rawError.details.join(', ') : rawError.err)}`;
    } else {
      displayMessage = `${optGenericMessage ? errorPrefix + ': ' + optGenericMessage : errorPrefix}`;
    }

    this._openSnack = this.snackBar.open(displayMessage, 'DISMISS', {
      panelClass: ['error']
    });
    this._openSnack.onAction().subscribe(() => {
      this._openSnack?.dismiss();
    });
  }

  dismiss() {
    this._openSnack?.dismiss();
    // free up memory
    this._openSnack = undefined;
  }
}

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

/**
 * Only allow access if an access denied error happened in the login callback route, if not redirect them to the root path.
 */
export const loginCallbackGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot, router = inject(Router)) => {
  const previousNavigationUrlTree = router.getCurrentNavigation()?.previousNavigation?.finalUrl;
  const previousUrl = previousNavigationUrlTree?.toString();

  if (previousUrl?.startsWith('/login/callback') && previousNavigationUrlTree?.queryParamMap.get('error') === 'access_denied') {
    return true;
  }

  return router.parseUrl('/');
};

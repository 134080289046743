import { Injectable } from '@angular/core';
import { IAccountStatus } from '@becksdevteam/becks-invoicing';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { AcknowledgementCode, IAcknowledgement } from '../models/acknowledgement.interface';
import { AcknowledgementsService } from './acknowledgements.service';
import { ApiService } from './api.service';

type UpdateAccountStatus = {
  accountStatusID: number;
  acknowledgementID: number;
  custID: number;
};

type ToggleAccountFinalization = 'finalize' | 'unfinalize';

@Injectable()
export class AccountFinalizationService {
  constructor(
    private acknowledgementService: AcknowledgementsService,
    private apiService: ApiService
  ) {}

  public finalize({ accountStatusID, custID }: { accountStatusID: number; custID: number }): Observable<IAccountStatus> {
    return this.toggleAccountFinalization({
      code: AcknowledgementCode.FINALIZE_ACCOUNT,
      updateType: 'finalize',
      custID,
      accountStatusID
    });
  }

  public unfinalize({ accountStatusID, custID }: { accountStatusID: number; custID: number }): Observable<IAccountStatus> {
    return this.toggleAccountFinalization({
      code: AcknowledgementCode.UN_FINALIZE_ACCOUNT,
      updateType: 'unfinalize',
      custID,
      accountStatusID
    });
  }

  private toggleAccountFinalization({
    code,
    accountStatusID,
    custID,
    updateType
  }: {
    code: AcknowledgementCode;
    accountStatusID: number;
    custID: number;
    updateType: ToggleAccountFinalization;
  }): Observable<IAccountStatus> {
    return this.acknowledgementService.saveAcknowledgement(code).pipe(
      concatMap(({ ID: acknowledgementID }) => this.acknowledgeAccountStatus({ accountStatusID, acknowledgementID })),
      concatMap(({ ID: acknowledgementID }) => this.updateAccountStatus({ accountStatusID, acknowledgementID, custID, updateType }))
    );
  }

  private acknowledgeAccountStatus({
    accountStatusID,
    acknowledgementID
  }: {
    accountStatusID: number;
    acknowledgementID: number;
  }): Observable<IAcknowledgement> {
    return this.apiService.post<IAcknowledgement>(`/accountStatus/${accountStatusID}/acknowledgements`, { acknowledgementID });
  }

  private updateAccountStatus({
    accountStatusID,
    acknowledgementID,
    custID,
    updateType
  }: UpdateAccountStatus & { updateType: ToggleAccountFinalization }): Observable<IAccountStatus> {
    return this.apiService.put<IAccountStatus>(`/accountStatus/${accountStatusID}/${updateType}`, {
      accountStatusID,
      acknowledgementID,
      custID
    });
  }
}

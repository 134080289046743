import { createReducer, on } from '@ngrx/store';

import { IUser, IUserSecurity, UserAccountStatus } from 'app/shared/models/user.interface';

import { UserActions } from '../actions/user.action';

const defaultUser: IUser = {
  email: '',
  firstName: '',
  lastName: '',
  pictureUrl: '',
  userName: '',
  oktaUserId: ''
};

const defaultSecurity: IUserSecurity = {
  id: 0,
  email: '',
  defaultCustID: 0,
  salesRepDlrID: 0,
  defaultBeckType: '',
  securityObjects: []
};

export interface UserState {
  user: IUser;
  security: IUserSecurity;
  accountStatus: UserAccountStatus;
  userLoaded: boolean;
  userLoading: boolean;
  userErred: boolean;
}

const initialState: UserState = {
  user: defaultUser,
  security: defaultSecurity,
  accountStatus: 'unknown',
  userLoaded: false,
  userLoading: false,
  userErred: false
};

export const userStateKey = 'user';
export const userReducer = createReducer(
  initialState,
  on(UserActions.loadUser, (state) => ({
    ...state,
    userLoading: true,
    userLoaded: false,
    userErred: false
  })),
  on(UserActions.loadUserFail, (state) => ({
    ...state,
    userLoaded: false,
    userLoading: false,
    userErred: true
  })),
  on(UserActions.loadUserSuccess, (state, { user, security, accountStatus }) => ({
    ...state,
    userLoaded: true,
    userLoading: false,
    userErred: false,
    user,
    security,
    accountStatus
  }))
);

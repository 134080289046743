<bng-modal (clickedOutside)="handleClose()" class="finalize-modal__wrapper">
  <div modalHeader>
    <div class="text-white">Confirm Finalization</div>
    <mat-icon (click)="handleClose()" class="clickable close">close</mat-icon>
  </div>
  <div modalContent class="flex flex-col text-black">
    <form [formGroup]="form">
      <div class="flex justify-center mb-6 mt-2">
        <bng-account-finalization-icon [accountStatusCode]="eAccountStatusCode.finalized" [size]="eSize.LARGE" />
      </div>
      <div>
        <p>
          Are you sure you want to mark {{ config.accountName }} ({{ config.accountID }}) as
          <span class="text-finalized-account font-semibold">Finalized</span>
          ? By doing so, you attest that the customer:
        </p>
        <div *ngIf="config.isDealerOrSeedAdvisor" class="flex flex-col mt-4 mb-4 ml-4" formArrayName="checkList">
          <mat-checkbox formControlName="0">All Customer Accounts are accurate and finalized.</mat-checkbox>
          <mat-checkbox formControlName="1">
            Dealer Inventory is accurate and
            <span class="underline">anything</span>
            remaining is to be billed to the dealership directly.
          </mat-checkbox>
          <mat-checkbox formControlName="2">All Returns have been physically received by Beck's.</mat-checkbox>
        </div>
        <div *ngIf="!config.isDealerOrSeedAdvisor" class="flex flex-col mt-4 mb-4 ml-4 customer-checklist">
          <p class="inline-flex">
            <span class="fas fa-square-check icon mt-1 mr-2"></span>
            Current Statement, including Seed, Commitment Rewards, and Trips, is accurate.
          </p>
          <p class="inline-flex">
            <span class="fas fa-square-check icon mt-1 mr-2"></span>
            President's Club Affidavit/s are entered and accurate.
          </p>
          <p class="inline-flex">
            <span class="fas fa-square-check icon mt-1 mr-2"></span>
            Dealer Funded Discount/s are entered and accurate.
          </p>
          <p class="inline-flex">
            <span class="fas fa-square-check icon mt-1 mr-2"></span>
            Early Order Discount/s are entered and accurate.
          </p>
          <p class="inline-flex">
            <span class="fas fa-square-check icon mt-1 mr-2"></span>
            All Payment/s and Cash Discount/s are entered and accurate.
          </p>
          <p class="inline-flex">
            <span class="fas fa-square-check icon mt-1 mr-2"></span>
            Replant/s are entered and accurate.
          </p>
        </div>
      </div>
      <p class="font-bold">
        I attest and affirm this information is complete and accurate, and mark {{ config.accountName }} ({{ config.accountID }}) as
        <span class="text-finalized-account font-semibold">Finalized</span>
        .
      </p>
    </form>
  </div>
  <div modalFooter>
    <div class="flex justify-between items-center w-full">
      <bng-button textColor="text-black" buttonType="button" (onClick)="handleClose()">Cancel</bng-button>
      <bng-button
        bgColor="bg-finalized-account"
        buttonType="button"
        [isDisabled]="config.isDealerOrSeedAdvisor && (form.pristine || form.invalid)"
        (onClick)="handleFinalize()"
      >
        Finalize
      </bng-button>
    </div>
  </div>
</bng-modal>

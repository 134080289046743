import { createSelector } from '@ngrx/store';
import { AccountFinalizationContext } from '../../shared/models/account-finalization.interface';
import * as fromUserSelectors from './user.selector';

export const selectAccountFinalizationContext = createSelector(
  fromUserSelectors.getUserHasFeatureCode('UNFINALIZE-DLR'),
  fromUserSelectors.getUserHasFeatureCode('UNFINALIZE-SA'),
  fromUserSelectors.getUserActiveCustID,
  fromUserSelectors.getUserDealerSalesRepID,
  fromUserSelectors.getUserIsDealer,
  fromUserSelectors.getUserIsDealerSalesRep,
  fromUserSelectors.getUserIsInternal,
  fromUserSelectors.getUserIsSeedAdvisor,
  (
    hasUnfinalizeDealerPermission,
    hasUnfinalizeSeedAdvisorPermission,
    userActiveCustomerID,
    userDealerSalesRepID,
    userIsDealer,
    userIsDealerSalesRep,
    userIsInternal,
    userIsSeedAdvisor
  ): AccountFinalizationContext => ({
    hasUnfinalizeDealerPermission,
    hasUnfinalizeSeedAdvisorPermission,
    userActiveCustomerID,
    userDealerSalesRepID,
    userIsDealer,
    userIsDealerSalesRep,
    userIsInternal,
    userIsSeedAdvisor
  })
);

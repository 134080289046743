export const getFamilyGroupIconPaths = () => ({
  CORN: 'assets/images/icons/corn-icon-fillable.svg',
  SOYBEANS: 'assets/images/icons/bean-icon-fillable.svg',
  WHEAT: 'assets/images/icons/wheat-icon-fillable.svg',
  OTHERCROP: 'assets/images/icons/alfalfa-icon-fillable.svg',
  'EL ALF': 'assets/images/icons/alfalfa-icon-fillable.svg',
  NONCROP: 'assets/images/icons/other-icon-gray.svg',
  PROMO: 'assets/images/icons/commitment-reward-icon-gray.svg'
});

export const getFamilyGroupIconPath = (familyCode: string): string => {
  const paths = getFamilyGroupIconPaths();

  return paths[familyCode] || '';
};

export const mapFamilyGroupFamilies = <T>(codes: string[], entities: Record<string, T | undefined> = {}): T[] =>
  codes.map((code) => entities[code] as T).filter((family) => !!family);

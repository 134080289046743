import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { FlagsmithFeatureFlagEntity } from 'app/store/reducers';

export const FlagsmithFeatureFlagActions = createActionGroup({
  source: 'Flagsmith Feature Flags',
  events: {
    flagsChanged: props<{ flagKeys: string[] }>(),
    addFlags: props<{ flags: FlagsmithFeatureFlagEntity[] }>(),
    upsertFlags: props<{ flags: FlagsmithFeatureFlagEntity[] }>(),
    removeFlags: props<{ flagKeys: string[] }>(),
    clearFlags: emptyProps()
  }
});

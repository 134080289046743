import { Injectable } from '@angular/core';

import { ApiService } from 'app/shared/services/api.service';
import { lastValueFrom } from 'rxjs';
import { ISystemStatus } from '../models/system.interface';

@Injectable()
export class StartupService {
  private _status: ISystemStatus;

  private readonly _defaultErrorStatus: ISystemStatus = {
    isAccessDisabled: true,
    maintenance: {
      isEnabled: true,
      title: 'BOSS Maintenance',
      message: 'Unfortunately the site is down for maintenance. Please check back soon.'
    }
  };

  constructor(private _apiService: ApiService) {}

  async loadSystemStatus(): Promise<void> {
    try {
      this._status = await lastValueFrom(this._apiService.get<ISystemStatus>('/general/system/status'));
    } catch (err) {
      this._status = this._defaultErrorStatus;
    }
  }

  getMaintenanceStatus() {
    return this._status.maintenance;
  }

  getAccessIsDisabled(): boolean {
    return this._status.isAccessDisabled;
  }
}

import { Params } from '@angular/router';

import { createSelector } from '@ngrx/store';
import _ from 'lodash';

import { CustomRouterState, getRouterState } from '../reducers';

export const getRouterReducerState = createSelector(getRouterState, (state) => state?.state);
export const getRouterReducerStateUrl = createSelector(getRouterReducerState, (routerState) => routerState?.url);
export const getRouterReducerStateUrlWithoutQueryParams = createSelector(getRouterReducerState, (routerState) => {
  return routerState?.urlWithoutQueryParams;
});

export const getRouterQueryParams = createSelector(
  getRouterReducerState,
  (routerReducerState: CustomRouterState) => routerReducerState?.queryParams
);

export const getRouterParams = createSelector(getRouterReducerState, (routerReducerState: CustomRouterState) => routerReducerState?.params);

export const getAllParams = createSelector(getRouterReducerState, (routerReducerState: CustomRouterState) => routerReducerState?.allParams);
export const getIdParam = createSelector(getRouterReducerState, (routerReducerState: CustomRouterState): number => {
  const idParam = _.find(routerReducerState?.allParams, 'id')?.id;
  return idParam ? parseInt(idParam, 10) : 0;
});

// pass in the query param key in string format and this selector returns
// the value for that key in the queryParams array
export const getRouterQueryParamByToken = (props: any) =>
  createSelector(getRouterQueryParams, (queryParams) => {
    if (queryParams && props) {
      return queryParams[props];
    }
  });

/* This selector takes the entire router URL stack and pulls out the route parameters and
 * places them in one single array that the application can pull from.
 * ex. { itineraryId: "5"
 *       tripCalendarDate: "07102020"
 *       tripID: "95"
 *     }
 */
export const getRouterParamsFlattened = createSelector(getAllParams, (allParamsAsArray) => {
  const allParams: object = {};

  _.each(allParamsAsArray, (paramArray: Params[]) => {
    Object.assign(allParams, { ...paramArray });
  });

  return allParams;
});

<div class="becks-quick-family-filter">
  <button
    *ngFor="let family of familyGroups"
    [class.selected]="family.code === selectedFamilyGroup.code"
    (click)="handleFamilyGroupSelected(family)"
    mat-raised-button
    class="becks-quick-family-filter__button"
  >
    {{ family.desc | titlecase }}
  </button>
</div>

import { Injectable } from '@angular/core';
import { Observable, of, OperatorFunction, pipe, Subject } from 'rxjs';
import { concatMap, filter, finalize, map, tap } from 'rxjs/operators';

export interface IContextualLoading {
  context: string | null;
  loading: boolean;
}

@Injectable()
export class LoadingService {
  private _loading: Subject<IContextualLoading> = new Subject<IContextualLoading>();
  private _loading$: Observable<IContextualLoading> = this._loading.asObservable();

  start(context: string | null = null) {
    this._loading.next({ context, loading: true });
  }

  end(context: string | null = null) {
    this._loading.next({ context, loading: false });
  }

  loadingUntilCompleted<T>(context: string | null = null, obs$: Observable<T>): Observable<T> {
    return of(null).pipe(
      tap(() => {
        this.start(context);
      }),
      concatMap(() => obs$),
      finalize(() => {
        this.end(context);
      })
    );
  }

  get isLoading$(): Observable<boolean> {
    return this._loading$.pipe(this.contextualLoadingFor(null));
  }

  get isLoadingFor$(): Observable<IContextualLoading> {
    return this._loading$;
  }

  isLoading(context: string) {
    return this.isLoadingFor$.pipe(this.contextualLoadingFor(context));
  }

  private contextualLoadingFor(contextName: string | null): OperatorFunction<IContextualLoading, boolean> {
    return pipe(
      filter(({ context }) => context === contextName),
      this.mapToIsLoading()
    );
  }

  private mapToIsLoading(): OperatorFunction<IContextualLoading, boolean> {
    return pipe(map(({ loading }) => loading));
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { dealersAdapter, SalesLookupState, salesLookupStateKey } from '../reducers/sales-lookup.reducer';

export const getSalesLookupState = createFeatureSelector<SalesLookupState>(salesLookupStateKey);

const selectDealersModelEntities = createSelector(getSalesLookupState, (state) => state.model.dealers);

/* Entity selectors */
export const {
  selectAll: selectDealers,
  selectEntities: selectDealersEntities,
  selectIds: selectDealersIDs,
  selectTotal: selectDealersTotal
} = dealersAdapter.getSelectors(selectDealersModelEntities);

export const selectDealersLoaded = createSelector(getSalesLookupState, (state) => state.viewModel.dealersLoaded);
export const selectDealersLoading = createSelector(getSalesLookupState, (state) => state.viewModel.dealersLoading);
export const selectDealersLoadErred = createSelector(getSalesLookupState, (state) => state.viewModel.dealersLoadErred);

import { Injectable, Renderer2, RendererFactory2, RendererStyleFlags2 } from '@angular/core';

@Injectable()
export class CustomRendererService implements Renderer2 {
  private _renderer: Renderer2;

  constructor(private _rendererFactory: RendererFactory2) {
    this._renderer = this._rendererFactory.createRenderer(null, null);
  }

  get data(): { [key: string]: any } {
    return this._renderer.data;
  }

  destroy(): void {
    this._renderer.destroy();
  }

  destroyNode(node: any): void {
    if (this._renderer.destroyNode) {
      this._renderer.destroyNode(node);
    }
  }

  createElement(name: string, namespace?: string | null) {
    return this._renderer.createElement(name, namespace);
  }

  createComment(value: string) {
    this._renderer.createComment(value);
  }

  createText(value: string) {
    this._renderer.createText(value);
  }

  appendChild(parent: any, newChild: any): void {
    this._renderer.appendChild(parent, newChild);
  }

  insertBefore(parent: any, newChild: any, refChild: any): void {
    this._renderer.insertBefore(parent, newChild, refChild);
  }

  removeChild(parent: any, oldChild: any, isHostElement?: boolean): void {
    this._renderer.removeChild(parent, oldChild, isHostElement);
  }

  /**
   * Custom implementation of `selectRootElement` to avoid throwing errors upon not finding element
   * @param selectorOrNode The DOM element.
   * @param preserveContent Whether the contents of the root element
   * should be preserved (default behavior), or cleared upon bootstrap.
   *
   * Use with `ViewEncapsulation.ShadowDom` to allow simple native
   * content projection via `<slot>` elements.
   *
   * @returns The root element or `undefined`.
   */
  selectRootElement(selectorOrNode: any, preserveContent: boolean = true): any | undefined {
    try {
      const node = this._renderer.selectRootElement(selectorOrNode, preserveContent);
      return node;
    } catch (e) {
      return undefined;
    }
  }

  parentNode(node: any) {
    return this._renderer.parentNode(node);
  }

  nextSibling(node: any) {
    return this._renderer.nextSibling(node);
  }

  setAttribute(el: any, name: string, value: string, namespace?: string | null): void {
    this._renderer.setAttribute(el, name, value, namespace);
  }

  removeAttribute(el: any, name: string, namespace?: string | null): void {
    this._renderer.removeAttribute(el, name, namespace);
  }

  addClass(el: any, name: string): void {
    this._renderer.addClass(el, name);
  }

  removeClass(el: any, name: string): void {
    this._renderer.removeClass(el, name);
  }

  setStyle(el: any, style: string, value: any, flags?: RendererStyleFlags2): void {
    this._renderer.setStyle(el, style, value, flags);
  }

  removeStyle(el: any, style: string, flags?: RendererStyleFlags2): void {
    this._renderer.removeStyle(el, style, flags);
  }

  setProperty(el: any, name: string, value: any): void {
    this._renderer.setProperty(el, name, value);
  }

  setValue(node: any, value: string): void {
    this._renderer.setValue(node, value);
  }

  listen(target: any, eventName: string, callback: (event: any) => boolean | void): () => void {
    return this._renderer.listen(target, eventName, callback);
  }
}

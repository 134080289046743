export enum UserAgent {
  IE10BELOW = 'IE10BELOW',
  IE11 = 'IE11',
  EDGE = 'EDGE',
  CHROME = 'CHROME',
  SAFARI = 'SAFARI',
  OPERA = 'OPERA',
  FIREFOX = 'FIREFOX'
}

export enum DisplayMode {
  LIGHT = 'light',
  DARK = 'dark'
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgIf, NgClass } from '@angular/common';
import { ProgressButtonDirective } from '../../../directives/progress-button.directive';
import { RouterLinkActive } from '@angular/router';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'bng-button',
  template: `<button
    type="{{ buttonType }}"
    mat-raised-button
    routerLinkActive
    #rla="routerLinkActive"
    [bngProgressButton]="!!loading"
    [disabled]="!!isDisabled"
    (click)="onClickEvent()"
    class="{{ textColor ?? 'text-white' }} {{ !isDisabled && !loading ? bgColor : '' }} {{
      rla.isActive || !isTab ? 'opacity-100' : 'opacity-75'
    }} w-full"
  >
    <i *ngIf="icon" [ngClass]="icon" class="mr-2"></i>
    <ng-content></ng-content>
  </button>`,
  styleUrls: ['./button.component.scss'],
  standalone: true,
  imports: [MatButton, RouterLinkActive, ProgressButtonDirective, NgIf, NgClass]
})
export class ButtonComponent {
  @Input() bgColor: string;
  @Input() buttonType?: string;
  @Input() isTab?: boolean;
  @Input() textColor?: string;
  @Input() isDisabled?: boolean | null;
  @Input() icon: string;
  @Input() loading: boolean | null = false;
  @Output() onClick = new EventEmitter();

  onClickEvent() {
    this.onClick.emit();
  }
}

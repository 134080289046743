import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  CanMatchFn,
  Data,
  Route,
  RouterStateSnapshot,
  UrlSegment
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from '../auth.service';

export const authenticationGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return canProceed(route.data, state.url);
};

export const authenticationChildGuard: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return authenticationGuard(route, state);
};

export const authenticationMatchGuard: CanMatchFn = (route: Route, segments: UrlSegment[]) => {
  return canProceed(route.data);
};

function canProceed(data?: Data, url?: string): Observable<boolean> {
  const authService = inject(AuthService);

  return authService.isAuthenticated$.pipe(
    switchMap((isAuthenticated) => {
      if (isAuthenticated) {
        return of(true);
      }

      /**
       * Store the current path and then send to log in
       */
      authService.loginRedirect(url);

      return of(false);
    })
  );
}

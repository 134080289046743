import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FamilyGroupCode, IFamily, IFamilyGroupWithIcons } from 'app/library/becks-family';
import { getFamilyGroupIconPath, mapFamilyGroupFamilies } from 'app/shared/helpers/family.helper';

import { familiesAdapter, ProductsState, productsStateKey } from '../reducers/products.reducer';

export const getProductsState = createFeatureSelector<ProductsState>(productsStateKey);

const getProductFamiliesEntity = createSelector(getProductsState, (state) => state.model.families);
export const getProductFamiliesLoaded = createSelector(getProductsState, (state) => state.viewModel.familiesLoaded);
export const getProductFamiliesLoading = createSelector(getProductsState, (state) => state.viewModel.familiesLoading);
export const getProductFamiliesLoadErred = createSelector(getProductsState, (state) => state.viewModel.familiesLoadErred);

/* Entity selectors */
const familyEntitySelectors = familiesAdapter.getSelectors();
export const getProductFamilies = createSelector(getProductFamiliesEntity, familyEntitySelectors.selectAll);
export const getProductFamiliesEntities = createSelector(getProductFamiliesEntity, familyEntitySelectors.selectEntities);
export const getProductFamiliesExceptFamily75 = createSelector(getProductFamilies, (families) =>
  families.filter((family) => family.ID !== 75)
);

/** Derived Selectors */
export const getProductFamilyGroups = createSelector(getProductFamiliesEntities, (entities): IFamilyGroupWithIcons[] => {
  const groups: IFamilyGroupWithIcons[] = [
    {
      code: FamilyGroupCode.corn,
      desc: 'Corn',
      families: mapFamilyGroupFamilies<IFamily>(['CORN'], entities),
      iconPath: getFamilyGroupIconPath(FamilyGroupCode.corn)
    },
    {
      code: FamilyGroupCode.soybeans,
      desc: 'Soybeans',
      families: mapFamilyGroupFamilies<IFamily>(['SOYBEANS'], entities),
      iconPath: getFamilyGroupIconPath(FamilyGroupCode.soybeans)
    },
    {
      code: FamilyGroupCode.wheat,
      desc: 'Wheat',
      families: mapFamilyGroupFamilies<IFamily>(['WHEAT'], entities),
      iconPath: getFamilyGroupIconPath(FamilyGroupCode.wheat)
    },
    {
      code: FamilyGroupCode.otherCrop,
      desc: 'Alfalfa & Other Seed',
      families: mapFamilyGroupFamilies<IFamily>(['OATS SG', 'MILO', 'EL ALF', 'COV CROP', 'FORAGES', 'SWTCORN'], entities),
      iconPath: getFamilyGroupIconPath(FamilyGroupCode.otherCrop)
    },
    {
      code: FamilyGroupCode.promo,
      desc: 'Commitment Rewards',
      families: mapFamilyGroupFamilies<IFamily>(['PROMO'], entities),
      iconPath: getFamilyGroupIconPath(FamilyGroupCode.promo)
    },
    {
      code: FamilyGroupCode.nonCrop,
      desc: 'Other Items',
      families: mapFamilyGroupFamilies<IFamily>(
        [
          'BALFWD',
          'GVNC OAT',
          'NRETSEED',
          'ToTAcres',
          'INBRED',
          'BBEDDING',
          'INOCS',
          'PLR BOX',
          'PNR PLTS',
          'SLS AIDS',
          'BLK SOY',
          'WH CORN',
          'WH SOY',
          'WH WHT',
          'SILINOCS',
          'BLK WHT',
          'TP Corn',
          'SHCKBALE',
          'SALESTAX',
          'FRM PRDS',
          'RENTINC',
          'INTINC',
          'NTINTINC',
          'MERCHINC',
          'OTHINC',
          'CCORN',
          'CBEAN',
          'CWHEAT',
          'CATTLE',
          'OTHINCXT',
          'CONTINC',
          'Research',
          'DCDCORN',
          'DCDBEANS',
          'DCDWHEAT',
          'CROP IMG',
          'TECH',
          'EXPENSE',
          'PACKAGE',
          'CR MEMO',
          'SHARED',
          'REFUNDS',
          'MISC',
          'CD CARRY',
          'BONXFER',
          'NOCDADJ'
        ],
        entities
      ),
      iconPath: getFamilyGroupIconPath(FamilyGroupCode.nonCrop)
    }
  ];

  return groups;
});

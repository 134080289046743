import { Injectable } from '@angular/core';
import { IProduct } from '@becksdevteam/becks-invoicing/dist';
import { Observable } from 'rxjs';

import { IFamily } from '../models/options.interface';
import { ApiService } from './api.service';

@Injectable()
export class ProductsService {
  constructor(private _apiService: ApiService) {}

  public fetchFamilies(): Observable<IFamily[]> {
    return this._apiService.get('/products/families');
  }

  public fetchParentProductIDs(familyID: number): Observable<string[]> {
    return this._apiService.get(`/products/parentsByFamily?familyID=${familyID}`);
  }

  public fetchProductIDs(parentID: number): Observable<string[]> {
    return this._apiService.get(`/products/productsByParent?parentID=${parentID}`);
  }

  public fetchProductDetail(parentID: number | string): Observable<IProduct> {
    return this._apiService.get(`/products/${parentID}/productDetail`);
  }
}

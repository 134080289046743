/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IUser, IUserPreference, IUserSecurity, UserAccountStatus } from '../models/user.interface';
import { ApiService } from './api.service';

@Injectable()
export class UserService {
  constructor(private _apiService: ApiService) {}

  saveUserPreference<T>(settingCode: string, settingValue: T, salesYear?: string): Observable<IUserPreference<T>> {
    return this._apiService.put(`/user/settings?settingCode=${settingCode}${salesYear ? '&salesYear=' + salesYear : ''}`, settingValue);
  }

  fetchUserPreference<T>(settingCode: string, salesYear?: string): Observable<IUserPreference<T>> {
    return this._apiService.get(`/user/settings?settingCode=${settingCode}${salesYear ? '&salesYear=' + salesYear : ''}`);
  }

  fetchUser(): Observable<IUser> {
    return this._apiService.get(`/user/me`);
  }

  fetchUserSecurity(beckUserId?: number): Observable<IUserSecurity> {
    return this._apiService.get(`/user/${beckUserId}/security`);
  }

  fetchUserAccountStatus(): Observable<{ status: UserAccountStatus }> {
    return this._apiService.get(`/auth/account-status`);
  }
}

export enum FamilyGroupCode {
  corn = 'CORN',
  soybeans = 'SOYBEANS',
  wheat = 'WHEAT',
  otherCrop = 'OTHERCROP',
  promo = 'PROMO',
  nonCrop = 'NONCROP'
}

export enum FamilyCode {
  balance_forward = 'BALFWD',
  better_bedding = 'BBEDDING',
  bulk_soybeans = 'BLK SOY',
  bulk_wheat = 'BLK WHT',
  bonus_transfer = 'BONXFER',
  cattle = 'CATTLE',
  commercial_soybeans = 'CBEAN',
  commercial_corn = 'CCORN',
  cash_discount_carry_over = 'CD CARRY',
  contingent_income = 'CONTINC',
  corn = 'CORN',
  cover_crop = 'COV CROP',
  cr_memo_ap_adjust = 'CR MEMO',
  crop_health_image = 'CROP IMG',
  commercial_wheat = 'CWHEAT',
  discard_soybeans = 'DCDBEANS',
  discard_corn = 'DCDCORN',
  discard_wheat = 'DCDWHEAT',
  alfalfa = 'EL ALF',
  expense = 'EXPENSE',
  forages = 'FORAGES',
  farm_prods = 'FRM PRDS',
  government_no_charge_oats = 'GVNC OAT',
  inbred = 'INBRED',
  seed_inoculants = 'INOCS',
  interest_income = 'INTINC',
  merchandise_for_resale = 'MERCHINC',
  milo = 'MILO',
  misc = 'MISC',
  non_cd_adjustment = 'NOCDADJ',
  non_retail_seed = 'NRETSEED',
  non_tax_interest_income = 'NTINTINC',
  oats_and_small_grains = 'OATS SG',
  other_income = 'OTHINC',
  other_income_extended_terms = 'OTHINCXT',
  packing = 'PACKAGE',
  planter_box_treatment = 'PLR BOX',
  planter_plates = 'PNR PLTS',
  promo = 'PROMO',
  refuns = 'REFUNDS',
  rental_income = 'RENTINC',
  research_income = 'Research',
  sales_tax = 'SALESTAX',
  shared_account = 'SHARED',
  shuck_bales = 'SHCKBALE',
  silage_inoculants = 'SILINOCS',
  sales_aids = 'SLS AIDS',
  soybeans = 'SOYBEANS',
  sweet_corn = 'SWTCORN',
  technology = 'TECH',
  corn_test_plot = 'TP Corn',
  total_acres = 'ToTAcres',
  wholesale_corn = 'WH CORN',
  wholesale_soybean = 'WH SOY',
  wholesale_wheat = 'WH WHT',
  wheat = 'WHEAT'
}

export const FamilyIdMapping = {
  1: FamilyCode.corn,
  2: FamilyCode.soybeans,
  3: FamilyCode.wheat,
  4: FamilyCode.oats_and_small_grains,
  6: FamilyCode.milo,
  7: FamilyCode.alfalfa,
  8: FamilyCode.cover_crop,
  9: FamilyCode.forages,
  25: FamilyCode.silage_inoculants
};

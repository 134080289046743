import { Injectable } from '@angular/core';

import _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ISalesYear } from '../models/sales-year.interface';

import { ApiService } from '../../shared/services/api.service';

@Injectable()
export class GlobalService {
  currentSalesYear$: BehaviorSubject<ISalesYear | undefined> = new BehaviorSubject(undefined);
  salesYears$: BehaviorSubject<ISalesYear[]> = new BehaviorSubject<ISalesYear[]>([]);

  constructor(private _apiService: ApiService) {}

  public fetchSalesYears(): Observable<ISalesYear[]> {
    return this._apiService.get(`/global/salesYears`).pipe(
      tap((salesYears: ISalesYear[]) => {
        const currYear = _.find(salesYears, (year: ISalesYear) => year.yearsFromCurrentYear === 0);
        this.currentSalesYear$.next(currYear);
        this.salesYears$.next(salesYears);
      })
    );
  }
}

import { Injectable } from '@angular/core';

import { ICustomer } from '@becksdevteam/becks-invoicing';
import _ from 'lodash';
import { Observable } from 'rxjs';

import { ApiService } from 'app/shared/services/api.service';

export interface SearchCustomersParams extends ICustomer {
  dealersOnly?: boolean;
}
@Injectable()
export class CustomerSearchService {
  constructor(private _apiService: ApiService) {}

  searchCustomers(customerParams: Partial<SearchCustomersParams>): Observable<ICustomer[]> {
    return this._apiService.get(`/customers/search?${this._buildQueryParams(customerParams)}`);
  }

  getAllCustomers(customerParams: Partial<SearchCustomersParams>, results: number): Observable<ICustomer[]> {
    return this._apiService.get(`/customers/search?${this._buildQueryParams(customerParams, results)}`);
  }

  private _buildQueryParams(params: Partial<SearchCustomersParams>, results?: number): string {
    let query: string = '';
    query += params.ID ? `custID=${params.ID}&` : '';
    query += params.dealer && (params.dealer.ID || params.dealer.ID === 0) ? `dealerID=${params.dealer.ID}&` : '';
    query += params.dsm && params.dsm.ID ? `dsmID=${params.dsm.ID}&` : '';
    query += params.farmName ? `farmName=${params.farmName}&` : '';
    query += params.firstName ? `firstName=${params.firstName}&` : '';
    query += params.lastName ? `lastName=${params.lastName}&` : '';
    query += params.city ? `city=${params.city}&` : '';
    query += params.state ? `state=${params.state}&` : '';
    query += params.zip ? `zip=${params.zip}&` : '';
    query += params.county && params.county ? `county=${params.county}&` : '';
    query += params.phoneNumber ? `phoneNumber=${params.phoneNumber}&` : '';
    query += params.email ? `email=${params.email}&` : '';
    query += results ? `results=${results}&` : '';
    query += params.dealersOnly ? `dealersOnly=${params.dealersOnly}&` : '';

    return _.trimEnd(query, '&');
  }
}

import { Inject, Injectable } from '@angular/core';

import { OktaAuth, UserClaims } from '@okta/okta-auth-js';

import { Observable, defer } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { AuthConfig } from './auth.models';
import { BECKS_AUTH_CONFIG } from './auth.tokens';

/**
 * Scrub scopes to ensure `openid` is included
 *
 * @param scopes Okta scopes
 */
const scrubScopes = (scopes: string[]): string[] => {
  if (scopes.indexOf('openid') >= 0) {
    return scopes;
  }

  return ['openid', ...scopes];
};

@Injectable()
export class AuthService {
  isAuthenticated$: Observable<boolean>;

  protected _oktaAuth: OktaAuth;

  constructor(@Inject(BECKS_AUTH_CONFIG) private readonly _config: AuthConfig) {
    /**
     * Scrub scopes to ensure 'openid' is included
     */
    this._config.scopes = Array.isArray(this._config.scopes) ? scrubScopes(this._config.scopes) : ['openid', 'email'];

    /**
     * Set cookies `sameSite` property if one was not provided
     * A value of `lax` will avoid being blocked by user "3rd party" cookie settings
     * but will block iframe embedding
     * https://web.dev/samesite-cookies-explained/
     */
    if (!this._config.cookies?.sameSite) {
      this._config.cookies = Object.assign({}, this._config.cookies, { sameSite: 'lax' });
    }

    /**
     * Create Okta Auth client
     */
    this._oktaAuth = new OktaAuth(this._config);

    /**
     * Create auth observables
     */
    this.isAuthenticated$ = defer(() => this._oktaAuth.isAuthenticated()).pipe(
      map((isAuthenticated) => isAuthenticated),
      distinctUntilChanged()
    );
  }

  /**
   * Performs a full page redirect to Okta based on the initial configuration.
   *
   * @param fromUri Path to push the user to after successful authentication
   */
  async loginRedirect(fromUri?: string): Promise<void> {
    return this._oktaAuth.signInWithRedirect({
      originalUri: fromUri
    });
  }

  /**
   * Sign out from current session with a redirect to Okta to
   * terminate session cookie
   */
  async logoutRedirect(redirectUri?: string): Promise<void> {
    // If a relative path was passed, convert to absolute URI
    if (redirectUri?.startsWith('/')) {
      redirectUri = window.location.origin + redirectUri;
    }

    // redirect to Okta to kill session
    await this._oktaAuth.signOut({
      postLogoutRedirectUri: redirectUri,
      clearTokensBeforeRedirect: true,
      revokeAccessToken: true,
      revokeRefreshToken: true
    });
  }

  /**
   * Returns the current accessToken in the tokenManager.
   *
   * @returns access token or undefined, if one was not found
   */
  async getAccessToken(): Promise<string | null> {
    try {
      const accessToken = await this._oktaAuth.getOrRenewAccessToken();
      return accessToken;
    } catch (err) {
      // The user no longer has an existing SSO session in the browser.
      // (OIDC error `login_required`)
      // Ask the user to authenticate again.
      return null;
    }
  }

  /**
   * Returns user claims from the /userinfo endpoint.
   * https://developer.okta.com/docs/reference/api/oidc/#userinfo
   *
   * @return User token claims (user information)
   */
  async getUserInfo(): Promise<UserClaims> {
    return this._oktaAuth.token.getUserInfo();
  }

  /**
   * Parses the tokens from the callback URL.
   */
  async handleRedirect(): Promise<void> {
    if (this._oktaAuth.isLoginRedirect()) {
      // handle storing tokens
      await this._oktaAuth.handleRedirect();
    }
  }
}

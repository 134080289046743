import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app-main.config';
import { AppComponent } from './app/app.component';
import environment from './app/app.config';

if (environment.production) {
  enableProdMode();
  /** The code here inserts script tags in the index.html <head> that render the tags as noted below */
  /**
   * <!-- Global site tag (gtag.js) - Google Analytics -->
   * <script async src="https://www.googletagmanager.com/gtag/js?id=UA-32808172-1"></script>
   * <script>
   * window.dataLayer = window.dataLayer || [];
   * function gtag(){dataLayer.push(arguments);}
   * gtag('js', new Date());
   * gtag('config', 'UA-32808172-1');
   * </script>
   */

  // Create first script tag with Google Tag Manager link
  const tagManagerScriptEl = document.createElement('script');
  tagManagerScriptEl.async = true;
  tagManagerScriptEl.src = 'https://www.googletagmanager.com/gtag/js?id=UA-32808172-1';
  document.head.appendChild(tagManagerScriptEl);

  // Create second gtag script tag
  const gtagScript = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', 'UA-32808172-1');`;

  const gtagScriptEl = document.createElement('script');
  gtagScriptEl.innerHTML = gtagScript;
  document.head.appendChild(gtagScriptEl);
}

// Dynamically create a global --vh variable for use in CSS
// This will let us use (var(--vh, 1vh) * 100) in height calculations
// to dynamically determine the viewport height correctly on mobile.
const handleViewHeightResize = () => {
  document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
};
handleViewHeightResize();
window.addEventListener('resize', handleViewHeightResize);

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));

import { ActionReducerMap } from '@ngrx/store';
import {
  FlagsmithFeatureFlagState,
  flagsmithFeatureFlagReducer,
  flagsmithFeatureFlagStateKey
} from 'app/store/reducers/flagsmith-feature-flag.reducer';
import { CustomRouterState, routerReducerStateKey } from './router.reducer';

import { RouterReducerState, routerReducer } from '@ngrx/router-store';
import { ProductsState, productsReducer, productsStateKey } from 'app/store/reducers/products.reducer';
import { SalesLookupState, salesLookupReducer, salesLookupStateKey } from 'app/store/reducers/sales-lookup.reducer';
import { SalesYearsState, salesYearsReducer, salesYearsStateKey } from './sales-years.reducer';
import { SystemInfoState, systemInfoReducer, systemInfoStateKey } from './system-info.reducer';
import { UserState, userReducer, userStateKey } from './user.reducer';

export interface State {
  [routerReducerStateKey]: RouterReducerState<CustomRouterState>;
  [flagsmithFeatureFlagStateKey]: FlagsmithFeatureFlagState;
  [salesYearsStateKey]: SalesYearsState;
  [userStateKey]: UserState;
  [systemInfoStateKey]: SystemInfoState;
  [productsStateKey]: ProductsState;
  [salesLookupStateKey]: SalesLookupState;
}

// map meta-level reducers that do not have their own module but
//  need to be part of state
export const reducers: ActionReducerMap<State> = {
  [routerReducerStateKey]: routerReducer,
  [flagsmithFeatureFlagStateKey]: flagsmithFeatureFlagReducer,
  [salesYearsStateKey]: salesYearsReducer,
  [userStateKey]: userReducer,
  [systemInfoStateKey]: systemInfoReducer,
  [productsStateKey]: productsReducer,
  [salesLookupStateKey]: salesLookupReducer
};

export * from './flagsmith-feature-flag.reducer';
export * from './products.reducer';
export * from './router.reducer';
export * from './sales-lookup.reducer';
export * from './sales-years.reducer';
export * from './system-info.reducer';
export * from './user.reducer';

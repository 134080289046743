import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IUser, IUserSecurity, UserAccountStatus } from 'app/shared/models/user.interface';

export const UserActions = createActionGroup({
  source: 'User',
  events: {
    loadUser: emptyProps(),
    loadUserFail: props<{ error: Error }>(),
    loadUserSuccess: props<{ user: IUser; security: IUserSecurity; accountStatus: UserAccountStatus }>()
  }
});

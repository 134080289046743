export interface IFamily {
  ID: number;
  code: string;
  desc: string;
  display: string;
}

export interface IFamilyGroup {
  code: string;
  desc: string;
  families: IFamily[];
}

export interface IFamilyGroupWithIcons extends IFamilyGroup {
  iconPath: string;
}

import { ComponentRef, EnvironmentInjector, Injectable, Type, ViewContainerRef } from '@angular/core';
import { IAccountStatus } from '@becksdevteam/becks-invoicing';
import { OverrideAndExtend } from 'app/shared/utils/model.util';
import { Observable, OperatorFunction, Subject, Subscription, of, pipe } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { AccountFinalizationFinalizeModalComponent } from '../components/account-finalization-finalize-modal/account-finalization-finalize-modal.component';
import { AccountFinalizationUnfinalizeModalComponent } from '../components/account-finalization-unfinalize-modal/account-finalization-unfinalize-modal.component';
import { AccountFinalizationModalConfig } from '../models/account-finalization.interface';
import { AccountFinalizationService } from './account-finalization.service';
import { SnackbarService } from './snackbar.service';

type ConfirmationResult = { completed: boolean; updatedAccountStatus?: IAccountStatus | null };
type ConfirmationResultCompleted = OverrideAndExtend<ConfirmationResult, { updatedAccountStatus: IAccountStatus }>;
export function confirmationResultSuccessful(): OperatorFunction<ConfirmationResult, ConfirmationResultCompleted> {
  return pipe(
    filter(
      (confirmationResult: ConfirmationResult): confirmationResult is ConfirmationResultCompleted =>
        confirmationResult.completed && confirmationResult.updatedAccountStatus != null
    )
  );
}

@Injectable()
export class AccountFinalizationModalService {
  private modalRef: ComponentRef<AccountFinalizationFinalizeModalComponent | AccountFinalizationUnfinalizeModalComponent>;
  private modalConfirmation: Subject<boolean>;
  private closeSubscription: Subscription;
  private cancelSubscription: Subscription;
  private confirmSubscription: Subscription;

  constructor(
    private accountFinalizationService: AccountFinalizationService,
    private snackbarService: SnackbarService,
    private environmentInjector: EnvironmentInjector
  ) {}

  confirmFinalize(modalHostRef: ViewContainerRef, config: AccountFinalizationModalConfig): Observable<ConfirmationResult> {
    return this.createModalAndConfirm(modalHostRef, AccountFinalizationFinalizeModalComponent, config).pipe(
      switchMap((isProceeding): Observable<ConfirmationResult> => {
        if (isProceeding) {
          return this.accountFinalizationService
            .finalize({
              accountStatusID: config.accountStatus!.status.ID,
              custID: config.accountID
            })
            .pipe(
              map((updatedAccountStatus) => ({
                completed: true,
                updatedAccountStatus
              })),
              catchError((error) => {
                this.snackbarService.errorWithHandler(error, 'Error finalizing account');
                return of({
                  completed: false
                });
              })
            );
        }
        return of({
          completed: false
        });
      })
    );
  }

  confirmUnfinalize(modalHostRef: ViewContainerRef, config: AccountFinalizationModalConfig): Observable<ConfirmationResult> {
    return this.createModalAndConfirm(modalHostRef, AccountFinalizationUnfinalizeModalComponent, config).pipe(
      switchMap((isProceeding): Observable<ConfirmationResult> => {
        if (isProceeding) {
          return this.accountFinalizationService
            .unfinalize({
              accountStatusID: config.accountStatus!.status.ID,
              custID: config.accountID
            })
            .pipe(
              map((updatedAccountStatus) => ({
                completed: true,
                updatedAccountStatus
              })),
              catchError((error) => {
                this.snackbarService.errorWithHandler(error, 'Error unfinalizing account');
                return of({
                  completed: false
                });
              })
            );
        }

        return of({
          completed: false
        });
      })
    );
  }

  private close() {
    this.modalConfirmation.next(false);
    this.modalConfirmation.complete();
  }

  private confirm() {
    this.modalConfirmation.next(true);
    this.modalConfirmation.complete();
  }

  private createModalAndConfirm(
    modalHostRef: ViewContainerRef,
    modalComponent: Type<AccountFinalizationFinalizeModalComponent | AccountFinalizationUnfinalizeModalComponent>,
    config: AccountFinalizationModalConfig
  ): Observable<boolean> {
    modalHostRef.clear();

    this.modalRef = modalHostRef.createComponent(modalComponent, { environmentInjector: this.environmentInjector });
    const modalInstance = this.modalRef.instance;

    modalInstance.config = {
      ...config
    };

    this.closeSubscription = modalInstance.closeClicked.subscribe(() => {
      this.close();
      modalHostRef.clear();
      this.closeSubscription.unsubscribe();
    });

    this.cancelSubscription = modalInstance.cancelClicked.subscribe(() => {
      this.close();
      modalHostRef.clear();
      this.cancelSubscription.unsubscribe();
    });

    this.confirmSubscription = modalInstance.confirmClicked.subscribe(() => {
      this.confirm();
      modalHostRef.clear();
      this.confirmSubscription.unsubscribe();
    });

    this.modalConfirmation = new Subject<boolean>();

    return this.modalConfirmation.asObservable();
  }
}

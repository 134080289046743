/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import environment from '../../../app/app.config';
import { ContentType, RequestOptions, ResponseType } from '../models/http.interface';

@Injectable()
export class ApiService {
  apiURL: string;
  notificationApiUrl: string;

  private headers: HttpHeaders;
  private options: Record<string, any>;

  constructor(private _http: HttpClient) {
    this.apiURL = environment.apiUrl;

    this.headers = new HttpHeaders({
      'Content-Type': ContentType.Json,
      Accept: ContentType.Json
    });

    this.options = {
      headers: this.headers,
      observe: 'response',
      responseType: ResponseType.Json,
      withCredentials: true
    };
  }

  get<T = any>(path: string, options?: RequestOptions): Observable<T> {
    return this._http
      .get<HttpResponse<T>>(this.getUrl(path), {
        ...this.options,
        ...options
      } as Record<string, any>)
      .pipe(map(this.getBody));
  }

  post<T = any>(path: string, body: any, options?: RequestOptions): Observable<T> {
    let requestBody: FormData | string;

    if (body instanceof FormData) {
      options = {
        ...options,
        headers: new HttpHeaders({
          Accept: ContentType.Json,
          ...options?.headers
        })
      };
      requestBody = body;
    } else {
      requestBody = JSON.stringify(body);
    }

    return this._http
      .post<HttpResponse<T>>(this.getUrl(path), requestBody, {
        ...this.options,
        ...options
      } as Record<string, any>)
      .pipe(map(this.getBody));
  }

  put<T = any>(path: string, body: any): Observable<T> {
    return this._http.put(this.getUrl(path), JSON.stringify(body), this.options).pipe(map(this.getBody));
  }

  delete(path: string): Observable<any> {
    return this._http
      .delete(this.getUrl(path), {
        ...this.options,
        headers: {
          Accept: ContentType.Json
        }
      })
      .pipe(map(this.getBody));
  }

  setHeaders(headers: {}) {
    Object.keys(headers).forEach((header) => this.headers.set(header, headers[header]));
  }

  private getBody(response: HttpResponse<any>) {
    return response.body;
  }

  private getUrl(path: string): string {
    // check if the path is a full url
    if (path.startsWith('http')) {
      return path;
    }

    return `${this.apiURL}${path}`;
  }
}

import { Component, ViewEncapsulation } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';

// Confirm Text Button Color -- new input implemented in the modal component to allow us to enter specified confirmText strings
// while being able to change the styling of the confirm button background color while keeping the integretiy of the original component.
// To change the background color just add a css class with the color you want that matches the input you are sending to the confirmTextWarning,
// if no input is provided it defaults to the original green
@Component({
  selector: 'bng-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatButton, MatDialogClose]
})
export class ConfirmationModalComponent {
  public title: string;
  public message: string;
  public confirmText: string | undefined = 'OK';
  public cancelText: string | undefined = 'Cancel';
  public confirmTextButtonColor: string | undefined = 'Delete';
}

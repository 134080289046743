import { createReducer, on } from '@ngrx/store';
import { ISalesYear } from '../../shared/models/sales-year.interface';
import * as fromSalesYears from '../actions/sales-years.action';

export interface SalesYearsState {
  salesYears: ISalesYear[];
  salesYearsLoaded: boolean;
  salesYearsLoading: boolean;

  salesYearChanging: boolean;
  selectedSalesYear: ISalesYear | null;
  newSalesYear: ISalesYear | null;
}

const initialState: SalesYearsState = {
  salesYears: [],
  salesYearsLoaded: false,
  salesYearsLoading: false,

  salesYearChanging: false,
  selectedSalesYear: null,
  newSalesYear: null
};

export const salesYearsStateKey = 'salesYears';
export const salesYearsReducer = createReducer(
  initialState,
  on(fromSalesYears.loadSalesYears, (state) => ({
    ...state,
    salesYearsLoading: true
  })),
  on(fromSalesYears.loadSalesYearsSuccess, (state, { salesYears }) => ({
    ...state,
    salesYears,
    salesYearsLoaded: true,
    salesYearsLoading: false
  })),
  on(fromSalesYears.loadSalesYearsFail, (state) => ({
    ...state,
    salesYearsLoaded: false,
    salesYearsLoading: false
  })),

  on(fromSalesYears.changeSalesYear, (state, action) => {
    return {
      ...state,
      salesYearChanging: true,
      newSalesYear: action.newSalesYear
    };
  }),
  on(fromSalesYears.changeSalesYearSuccess, (state) => {
    return {
      ...state,
      salesYearChanging: false,
      selectedSalesYear: state.newSalesYear,
      newSalesYear: null
    };
  })
);

import { createAction, props } from '@ngrx/store';

import { ISalesYear } from '../../shared/models/sales-year.interface';

export const loadSalesYears = createAction('[Global] Load Sales Years');
export const loadSalesYearsSuccess = createAction('[Global] Load Sales Years Success', props<{ salesYears: ISalesYear[] }>());
export const loadSalesYearsFail = createAction('[Global] Load Sales Years Fail', props<{ error: Error }>());

export const changeSalesYear = createAction('[Global] Change Sales Year', props<{ newSalesYear: ISalesYear }>());
export const changeSalesYearSuccess = createAction('[Global] Change Sales Year Success');

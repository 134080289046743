import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';

import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { filter, map, mergeMap } from 'rxjs/operators';
import { LoadingIndicatorComponent } from './shared/components/loading-indicator/bng-loading-indicator/bng-loading-indicator.component';

@Component({
  selector: 'bng-app-root',
  template: `<bng-loading-indicator [loading]="appLoading" /> <router-outlet></router-outlet> `,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [LoadingIndicatorComponent, RouterOutlet]
})
export class AppComponent implements OnInit {
  title = 'BOSS - [n]ew [g]eneration';
  appLoading = true;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _titleService: Title,
    private _angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics
  ) {}

  ngOnInit() {
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this._activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        this.appLoading = false;
        if (event.title) {
          this._titleService.setTitle(`BOSS | ${event.title}`);
        }
      });

    // the following line is required to start google analytics tracking
    this._angulartics2GoogleAnalytics.startTracking();
  }
}

import { HttpHeaders } from '@angular/common/http';

export enum ContentType {
  // Text Types
  PlainText = 'text/plain',
  Html = 'text/html',
  Css = 'text/css',
  JavaScript = 'text/javascript',
  Xml = 'text/xml',
  Csv = 'text/csv',
  Markdown = 'text/markdown',
  Calendar = 'text/calendar',

  // Application Types
  Json = 'application/json',
  XmlApplication = 'application/xml',
  XhtmlXml = 'application/xhtml+xml',
  JavaScriptApplication = 'application/javascript',
  Pdf = 'application/pdf',
  Zip = 'application/zip',
  FormUrlEncoded = 'application/x-www-form-urlencoded',
  OctetStream = 'application/octet-stream',
  JsonLd = 'application/ld+json',
  EpubZip = 'application/epub+zip',
  ApiJson = 'application/vnd.api+json',
  Excel = 'application/vnd.ms-excel',
  ExcelOpenXml = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PowerPoint = 'application/vnd.ms-powerpoint',
  PowerPointOpenXml = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  Word = 'application/msword',
  WordOpenXml = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  Tar = 'application/x-tar',
  Rar = 'application/x-rar-compressed',
  GitHubJson = 'application/vnd.github+json',

  // Image Types
  Jpeg = 'image/jpeg',
  Png = 'image/png',
  Gif = 'image/gif',
  Webp = 'image/webp',
  SvgXml = 'image/svg+xml',
  Bmp = 'image/bmp',
  Tiff = 'image/tiff',
  Icon = 'image/x-icon',

  // Audio/Video Types
  AudioMpeg = 'audio/mpeg',
  AudioWav = 'audio/wav',
  AudioOgg = 'audio/ogg',
  AudioWebm = 'audio/webm',
  VideoMp4 = 'video/mp4',
  VideoOgg = 'video/ogg',
  VideoWebm = 'video/webm',
  VideoAvi = 'video/avi',

  // Multipart Types
  FormData = 'multipart/form-data',
  ByteRanges = 'multipart/byteranges',
  Mixed = 'multipart/mixed',
  Alternative = 'multipart/alternative',

  // Font Types
  Woff = 'font/woff',
  Woff2 = 'font/woff2',
  Ttf = 'font/ttf',
  Otf = 'font/otf',

  // Other Types
  HttpMessage = 'message/http',
  ImdnXml = 'message/imdn+xml',
  PartialMessage = 'message/partial',
  Rfc822 = 'message/rfc822'
}

// export type ResponseType = 'arraybuffer' | 'blob' | 'json' | 'text';

export enum ResponseType {
  ArrayBuffer = 'arraybuffer',
  Blob = 'blob',
  Json = 'json',
  Text = 'text',
  Csv = 'csv'
}

export interface RequestOptions {
  headers?: HttpHeaders | Record<string, string | string[]>;
  params?: Record<string, any>;
  reportProgress?: boolean;
  responseType?: ResponseType;
  withCredentials?: boolean;
}

import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { Observable } from 'rxjs';

import { AsyncPipe, NgClass } from '@angular/common';
import { Store } from '@ngrx/store';
import { getSystemInfoBreakpointActiveByID, State } from 'app/store';
import { Breakpoints } from '../../../layout/models/breakpoints.interface';

// To use this modal you need to wrap your header/footer/content in a '<bng-modal></bng-modal' element.
// Add modalHeader, modalContent, and modalFooter attributes to the elements that should be inserted into
// their respective content child slots.  Look at the toolbox account component as an example on its usage

@Component({
  selector: 'bng-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgClass, AsyncPipe]
})
export class ModalComponent {
  @Input() showMainHeader: boolean;
  @Input() smallModal: boolean;
  @Input() warningModal: boolean;
  @Input() contentClass?: string;
  @Input() footerClass?: string;

  @Output() clickedOutside: EventEmitter<any> = new EventEmitter();

  treatAsMobile$: Observable<boolean> = this._sharedStore.select(
    getSystemInfoBreakpointActiveByID({
      breakpoint: Breakpoints.SM_DOWN
    })
  );

  constructor(private _sharedStore: Store<State>) {}

  handleClickOutside() {
    this.clickedOutside.emit();
  }
}

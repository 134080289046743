import { Observable } from 'rxjs';
import { ConfirmationModalComponent } from './confirmation-modal.component';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

// Confirm Text Button Color -- new input implemented in the modal component to allow us to enter specified confirmText strings
// while being able to change the styling of the confirm button background color while keeping the integretiy of the original component.
// To change the background color just add a css class with the color you want that matches the input you are sending to the confirmTextWarning,
// if no input is provided it defaults to the original green

@Injectable()
export class ModalService {
  constructor(private dialog: MatDialog) {}

  public confirm(
    title: string,
    message: string,
    confirmText?: string,
    cancelText?: string,
    disableClose?: boolean,
    confirmTextButtonColor?: string
  ): Observable<boolean> {
    const dialogRef: MatDialogRef<ConfirmationModalComponent> = this.dialog.open(ConfirmationModalComponent);
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.confirmText = confirmText ? confirmText : 'OK';
    dialogRef.componentInstance.cancelText = cancelText ? cancelText : 'Cancel';
    dialogRef.disableClose = disableClose;
    dialogRef.componentInstance.confirmTextButtonColor = confirmTextButtonColor ? confirmTextButtonColor : 'ok';

    return dialogRef.afterClosed();
  }

  public confirmByReference(
    title: string,
    message: string,
    confirmText?: string,
    cancelText?: string,
    disableClose?: boolean,
    confirmTextButtonColor?: string
  ): MatDialogRef<ConfirmationModalComponent> {
    const dialogRef: MatDialogRef<ConfirmationModalComponent> = this.dialog.open(ConfirmationModalComponent);
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.confirmText = confirmText ? confirmText : 'OK';
    dialogRef.componentInstance.cancelText = cancelText ? cancelText : 'Cancel';
    dialogRef.disableClose = disableClose;
    dialogRef.componentInstance.confirmTextButtonColor = confirmTextButtonColor ? confirmTextButtonColor : 'ok';

    return dialogRef;
  }

  // for providing a custom template for the confirm modal
  public confirmCustomTemplate(modalTemplate: any, data?: any): Observable<any> {
    const dialogRef: MatDialogRef<typeof modalTemplate> = this.dialog.open(modalTemplate, { data });

    return dialogRef.afterClosed();
  }
}

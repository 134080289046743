import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FlagsmithFlags } from 'app/shared/models/flagsmith-flags.enum';
import {
  FlagsmithFeatureFlagState,
  flagsmithFeatureFlagAdapter,
  flagsmithFeatureFlagStateKey
} from 'app/store/reducers/flagsmith-feature-flag.reducer';

export const selectFlagsmithFeatureFlagState = createFeatureSelector<FlagsmithFeatureFlagState>(flagsmithFeatureFlagStateKey);

export const selectFlagsmithFeatureFlagsLoaded = createSelector(
  selectFlagsmithFeatureFlagState,
  (state: FlagsmithFeatureFlagState) => state.loaded
);

const { selectAll, selectEntities } = flagsmithFeatureFlagAdapter.getSelectors();
export const selectAllFlagsmithFeatureFlags = createSelector(selectFlagsmithFeatureFlagState, selectAll);
export const selectFlagsmithFeatureFlagEntities = createSelector(selectFlagsmithFeatureFlagState, selectEntities);

export const selectFlagsmithFeatureFlag = (featureFlagKey: FlagsmithFlags) =>
  createSelector(selectFlagsmithFeatureFlagEntities, (featureFlagEntities) => {
    return featureFlagEntities[featureFlagKey];
  });

export const selectFlagsmithFeatureFlagIsEnabled = (featureFlagKey: FlagsmithFlags) =>
  createSelector(selectFlagsmithFeatureFlag(featureFlagKey), (featureFlagEntity) => {
    return Boolean(featureFlagEntity?.enabled);
  });

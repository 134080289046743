import { Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';

@Injectable()
export class CustomGestureConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement): any {
    const mc = super.buildHammer(element) as any;

    mc.set({ touchAction: 'pan-y' });

    // Your other configurations
    mc.get('swipe').set({ velocity: 0.3, threshold: 10 });

    return mc;
  }
}

import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { FlagsmithFeatureFlagActions } from 'app/store/actions/flagsmith-feature-flag.actions';
import { IFlagsmithFeature } from 'flagsmith-es/types';

export type FlagsmithFeatureFlagEntity = IFlagsmithFeature & { name: string };
export type FlagsmithFeatureFlagState = EntityState<FlagsmithFeatureFlagEntity> & {
  loaded: boolean;
};

export const flagsmithFeatureFlagAdapter = createEntityAdapter<FlagsmithFeatureFlagEntity>({ selectId: (entity) => entity.name });
const initialState = flagsmithFeatureFlagAdapter.getInitialState({ loaded: false });

export const flagsmithFeatureFlagStateKey = 'flagsmithFeatureFlags';
export const flagsmithFeatureFlagReducer = createReducer(
  initialState,
  on(FlagsmithFeatureFlagActions.addFlags, (state, { flags }) => {
    return flagsmithFeatureFlagAdapter.setAll(flags, { ...state, loaded: true });
  }),
  on(FlagsmithFeatureFlagActions.upsertFlags, (state, { flags }) => {
    return flagsmithFeatureFlagAdapter.upsertMany(flags, state);
  }),
  on(FlagsmithFeatureFlagActions.removeFlags, (state, { flagKeys }) => {
    return flagsmithFeatureFlagAdapter.removeMany(flagKeys, state);
  }),
  on(FlagsmithFeatureFlagActions.clearFlags, (state) => {
    return flagsmithFeatureFlagAdapter.removeAll({ ...state, loaded: false });
  })
);

export interface IAcknowledgement {
  ID: number;
  code: string;
  userName: string;
}

export enum AcknowledgementCode {
  CONFLICTING_EVENT = 'CONFLICT',
  OS = 'OS',
  FINALIZE_ACCOUNT = 'FINALIZE',
  UN_FINALIZE_ACCOUNT = 'UNFINALIZE'
}

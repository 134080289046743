<div class="login-callback">
  <div class="login-callback__container">
    <div class="login-callback__container-logo"></div>
    @if (!!error()) {
      <div class="login-callback__container-error">
        <i class="icon"></i>
        {{ error() }}
      </div>
    } @else {
      <h3 class="login-callback__container-title">You are being authenticated...</h3>
    }
    <p class="login-callback__container-content">
      If your browser does not redirect you back, please
      <a (click)="handleClickHere()">click here</a>
      to continue.
    </p>
  </div>
</div>

@if (loading === true) {
  <div class="loader-container">
    <div class="loader">
      <div class="loader-circle"></div>
      <div class="loader-line-mask">
        <div class="loader-line"></div>
      </div>
      <img src="assets/images/loader.svg" />
    </div>
  </div>
}

import { FlagsmithFeatureFlagEffects } from './flagsmith-feature-flag.effects';
import { ProductsEffects } from './products.effects';
import { RouterEffects } from './router.effects';
import { SalesLookupEffects } from './sales-lookup.effects';
import { SalesYearsEffects } from './sales-years.effect';
import { SystemInfoEffects } from './system-info.effects';
import { UserEffects } from './user.effect';

export const effects = [
  RouterEffects,
  FlagsmithFeatureFlagEffects,
  SalesYearsEffects,
  UserEffects,
  SystemInfoEffects,
  ProductsEffects,
  SalesLookupEffects
];

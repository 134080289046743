import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IFamilyGroup } from '../../models/family.interface';
import { MatButton } from '@angular/material/button';
import { NgFor, TitleCasePipe } from '@angular/common';

@Component({
  selector: 'lib-becks-quick-family-filter',
  templateUrl: './becks-quick-family-filter.component.html',
  styleUrls: ['./becks-quick-family-filter.component.scss'],
  standalone: true,
  imports: [NgFor, MatButton, TitleCasePipe]
})
export class BecksQuickFamilyFilterComponent {
  @Input() familyGroups: IFamilyGroup[];
  @Input() selectedFamilyGroup: IFamilyGroup;

  @Output() onFamilyGroupSelected: EventEmitter<IFamilyGroup> = new EventEmitter();

  constructor() {}

  handleFamilyGroupSelected(selectedFamilyGroup: IFamilyGroup) {
    this.onFamilyGroupSelected.emit(selectedFamilyGroup);
  }
}

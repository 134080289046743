import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ProductsService } from '../../shared/services/products.service';
import * as ProductsActions from '../actions/products.actions';

@Injectable()
export class ProductsEffects {
  constructor(
    private _actions$: Actions,
    private _productsService: ProductsService
  ) {}

  /** Load Product Families */
  loadFamilies$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProductsActions.loadProductFamilies),
      switchMap(() =>
        this._productsService.fetchFamilies().pipe(
          map((families) => ProductsActions.loadProductFamiliesSuccess({ data: families })),
          catchError((error) => of(ProductsActions.loadProductFamiliesFailure({ error })))
        )
      )
    )
  );
}

<div
  class="modal"
  [ngClass]="{
    warn: warningModal,
    mobile: (treatAsMobile$ | async),
    'show-main-header': showMainHeader,
    small: smallModal
  }"
>
  <div [class.mobile]="treatAsMobile$ | async" class="modal__container">
    <div class="modal__header">
      <ng-content select="[modalHeader]"></ng-content>
    </div>
    <div class="modal__content {{ contentClass }}">
      <ng-content select="[modalContent]"></ng-content>
    </div>
    <div class="modal__footer {{ footerClass }}">
      <ng-content select="[modalFooter]"></ng-content>
    </div>
  </div>
</div>
<div (click)="handleClickOutside()" class="modal__background"></div>

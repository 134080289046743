<bng-modal (clickedOutside)="handleClose()" class="unfinalize-modal__wrapper">
  <div modalHeader>
    <div class="text-white">Warning: Finalized Account</div>
    <mat-icon (click)="handleClose()" class="clickable close">close</mat-icon>
  </div>
  <div modalContent class="flex flex-col">
    <div class="flex justify-center mb-6 mt-2">
      <bng-account-finalization-icon [accountStatusCode]="eAccountStatusCode.unfinalized" [size]="eSize.LARGE" />
    </div>
    <div>
      <p class="text-black">
        <span class="font-semibold">Warning:</span>
        {{ config.accountName }} ({{ config.accountID }}) was marked
        <span class="text-finalized-account font-semibold">Finalized</span>
        by {{ config.accountStatus?.performedBy?.firstName }} {{ config.accountStatus?.performedBy?.lastName }} on
        {{ config.accountStatus?.performedBy?.date | date: 'M/d/yyyy' }}. Performing this action will result in {{ config.accountName }} ({{
          config.accountID
        }}) being
        <span class="text-unfinalized-account font-semibold">Un-Finalized</span>
        . Are you sure you would like to proceed?
      </p>
    </div>
  </div>
  <div modalFooter>
    <div class="flex justify-between items-center w-full">
      <bng-button textColor="text-black" buttonType="button" (onClick)="handleClose()">Cancel</bng-button>
      <bng-button bgColor="bg-unfinalized-account" buttonType="button" (onClick)="handleProceed()">Proceed</bng-button>
    </div>
  </div>
</bng-modal>

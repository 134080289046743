import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  CanMatchFn,
  Data,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment
} from '@angular/router';
import { Store } from '@ngrx/store';
import { SnackbarService } from 'app/shared/services/snackbar.service';
import * as fromReducers from 'app/store/reducers';
import * as fromSelectors from 'app/store/selectors';
import { Observable, of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

export const authorizationGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return waitForSecurityToLoad(route.data);
};

export const authorizationChildGuard: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  authorizationGuard(route, state);

export const authorizationMatchGuard: CanMatchFn = (route: Route, segments: UrlSegment[]) => waitForSecurityToLoad(route.data);

const checkPermissions = (
  data: Data | undefined,
  store: Store<fromReducers.State>,
  router: Router,
  snackbarService: SnackbarService
): Observable<boolean> => {
  // there is not data object
  if (!data) {
    return of(true);
  }

  // no features are required
  const roles: string[] = data.features;
  if (!Array.isArray(roles) || roles.length === 0) {
    return of(true);
  }

  return store.select(fromSelectors.getUserHasFeatureCodes(roles)).pipe(
    tap((isAuthorized) => {
      if (!isAuthorized) {
        snackbarService.error('You are not authorized to view that page.');
        router.navigate(['/']);
      }
    })
  );
};

const waitForSecurityToLoad = (data: Data | undefined): Observable<boolean> => {
  const store = inject(Store<fromReducers.State>);
  const router = inject(Router);
  const snackbarService = inject(SnackbarService);

  return store.select(fromSelectors.getUserLoaded).pipe(
    // only pass when user is authenticated
    filter((isUserLoaded) => isUserLoaded),
    // perform necessary loads
    switchMap(() => checkPermissions(data, store, router, snackbarService))
  );
};

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EAccountStatusCode } from '@becksdevteam/becks-invoicing';
import { AccountFinalizationModalConfig } from '../../models/account-finalization.interface';
import Size from '../../models/size.interface';
import { ButtonComponent } from '../buttons/button/button.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { NgIf } from '@angular/common';
import { AccountFinalizationIconComponent } from '../account-finalization-icon/account-finalization-icon.component';
import { MatIcon } from '@angular/material/icon';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'bng-account-finalization-finalize-modal',
  templateUrl: './account-finalization-finalize-modal.component.html',
  styleUrls: ['./account-finalization-finalize-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [ModalComponent, MatIcon, FormsModule, ReactiveFormsModule, AccountFinalizationIconComponent, NgIf, MatCheckbox, ButtonComponent]
})
export class AccountFinalizationFinalizeModalComponent implements OnInit {
  @Input()
  config: AccountFinalizationModalConfig;
  @Output()
  closeClicked: EventEmitter<void> = new EventEmitter();
  @Output()
  cancelClicked: EventEmitter<void> = new EventEmitter();
  @Output()
  confirmClicked: EventEmitter<void> = new EventEmitter();

  eSize = Size;
  eAccountStatusCode = EAccountStatusCode;

  form: FormGroup<{
    checkList: FormArray<FormControl<boolean>>;
  }>;

  private readonly dealerOrSeedAdvisorCheckListItemCount = 3;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      checkList: this.config.isDealerOrSeedAdvisor ? this.formBuilder.array(this.dealerOrSeedAdvisorCheckList) : undefined
    });
  }

  handleClose() {
    this.closeClicked.emit();
  }

  handleFinalize() {
    this.confirmClicked.emit();
  }

  private get dealerOrSeedAdvisorCheckList() {
    return Array.from(
      Array(this.dealerOrSeedAdvisorCheckListItemCount),
      () => new FormControl(false, { nonNullable: true, validators: Validators.requiredTrue })
    );
  }
}

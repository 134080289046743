import { ActivatedRouteSnapshot, Params, RouterState, RouterStateSnapshot } from '@angular/router';

import { Injectable } from '@angular/core';
import { RouterReducerState, RouterStateSerializer } from '@ngrx/router-store';
import { createFeatureSelector } from '@ngrx/store';
import _ from 'lodash';

export interface CustomRouterState {
  url: string;
  urlWithoutQueryParams: string;
  queryParams: Params;
  params: Params;
  allParams: Params[];
}

export const routerReducerStateKey = 'routerReducer';
export const getRouterStateUrl = (state: RouterState) => state.root.url;
export const getRouterState = createFeatureSelector<RouterReducerState<CustomRouterState>>(routerReducerStateKey);

@Injectable()
export class CustomRouteSerializer implements RouterStateSerializer<CustomRouterState> {
  serialize(routerState: RouterStateSnapshot): CustomRouterState {
    const { url } = routerState;
    const { queryParams } = routerState.root;
    const urlWithoutQueryParams = url.indexOf('?') > 0 ? url.substring(0, url.indexOf('?')) : url;

    // traverse router state tree to take a few properties and bind to our own ngrx state tree
    let state: ActivatedRouteSnapshot = routerState.root;
    while (state.firstChild) {
      state = state.firstChild;
    }
    // get the params of the current route only
    const { params } = state;

    // traverse the router state tree and compile ALL of the parameters into one object to be accessible by the store
    const allParams: any[] = [{ ...params }];
    while (state.parent) {
      state = state.parent;
      if (Object.keys(state.params).length > 0 && !_.find(allParams, state.params)) {
        allParams.push(state.params);
      }
    }

    return { url, urlWithoutQueryParams, queryParams, params, allParams };
  }
}

import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { EAccountStatusCode } from '@becksdevteam/becks-invoicing';
import { NgChanges } from '../../helpers/ng-changes.helper';
import { DisplayMode } from '../../models/metadata.interface';
import { Size } from '../../models/size.interface';

@Component({
  selector: 'bng-account-finalization-icon',
  templateUrl: './account-finalization-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgClass]
})
export class AccountFinalizationIconComponent implements OnChanges {
  @Input()
  accountStatusCode?: EAccountStatusCode | null;
  @Input()
  displayMode?: DisplayMode = DisplayMode.DARK;
  @Input()
  size?: Size = Size.MEDIUM;
  @Input()
  label?: string;

  eSize = Size;

  imgSrc: string | undefined;

  private readonly icon: { [K in EAccountStatusCode]: string } = {
    [EAccountStatusCode.finalized]: 'finalized',
    [EAccountStatusCode.notready]: 'unfinalizeable',
    [EAccountStatusCode.ready]: 'unfinalized',
    [EAccountStatusCode.unfinalized]: 'unfinalized'
  };

  private readonly lightModeSuffix = '_light';
  private readonly iconPath = 'assets/images/family-icons';
  private readonly iconFileTypeSuffix = 'png';

  ngOnChanges(changes: NgChanges<AccountFinalizationIconComponent>): void {
    if (changes.accountStatusCode) {
      this.imgSrc = this.getIconImgSrc(changes.accountStatusCode.currentValue);
    }
  }

  private getIconImgSrc(accountStatusCode?: EAccountStatusCode | null): string | undefined {
    if (!accountStatusCode) {
      return;
    }
    const maybeImgName: string | undefined = this.icon[accountStatusCode];
    return maybeImgName ? this.buildImgSrc(maybeImgName) : maybeImgName;
  }

  private buildImgSrc(imgName: string) {
    return `${this.iconPath}/${imgName}${this.lightOrDarkImg ?? ''}.${this.iconFileTypeSuffix}`;
  }

  private get lightOrDarkImg() {
    if (DisplayMode.LIGHT === this.displayMode) {
      return this.lightModeSuffix;
    }

    return null;
  }
}

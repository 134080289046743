import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarConfig, MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackBarListMessage } from 'app/shared/models/snackbar.interface';

@Component({
  selector: 'bng-snackbar-message-with-list',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgFor, MatButtonModule, MatSnackBarModule],
  templateUrl: './snackbar-message-with-list.component.html',
  styleUrls: ['./snackbar-message-with-list.component.scss']
})
export class SnackbarMessageWithListComponent implements OnInit {
  snackBarRef = inject(MatSnackBarRef);
  messageObjs: SnackBarListMessage[];

  ngOnInit(): void {
    const { data: snackBarConfigData }: MatSnackBarConfig<{ messageObjs: SnackBarListMessage[] }> =
      this.snackBarRef.containerInstance.snackBarConfig;
    if (snackBarConfigData && 'messageObjs' in snackBarConfigData) {
      this.messageObjs = snackBarConfigData.messageObjs;
    } else {
      throw new Error('SnackbarMessageWithListComponent requires a snackBarConfig with an array of SnackBarListMessage interfaces');
    }
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { EAccountStatusCode } from '@becksdevteam/becks-invoicing';
import { AccountFinalizationModalConfig } from '../../models/account-finalization.interface';
import Size from '../../models/size.interface';
import { DatePipe } from '@angular/common';
import { ButtonComponent } from '../buttons/button/button.component';
import { AccountFinalizationIconComponent } from '../account-finalization-icon/account-finalization-icon.component';
import { MatIcon } from '@angular/material/icon';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'bng-account-finalization-unfinalize-modal',
  templateUrl: './account-finalization-unfinalize-modal.component.html',
  styleUrls: ['./account-finalization-unfinalize-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [ModalComponent, MatIcon, AccountFinalizationIconComponent, ButtonComponent, DatePipe]
})
export class AccountFinalizationUnfinalizeModalComponent {
  @Input()
  config: AccountFinalizationModalConfig;
  @Output()
  closeClicked: EventEmitter<void> = new EventEmitter();
  @Output()
  cancelClicked: EventEmitter<void> = new EventEmitter();
  @Output()
  confirmClicked: EventEmitter<void> = new EventEmitter();

  eSize = Size;
  eAccountStatusCode = EAccountStatusCode;

  handleClose() {
    this.closeClicked.emit();
  }

  handleProceed() {
    this.confirmClicked.emit();
  }
}

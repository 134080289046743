import { ComponentRef, Directive, Input, OnChanges, Optional, Renderer2, ViewContainerRef } from '@angular/core';
import { MatButton, MatFabButton, MatIconButton, MatMiniFabButton } from '@angular/material/button';
import { ThemePalette } from '@angular/material/core';
import { MatProgressSpinner, ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { NgChanges } from '../helpers/ng-changes.helper';

@Directive({
  selector: '[bngProgressButton]',
  standalone: true
})
export class ProgressButtonDirective implements OnChanges {
  @Input()
  bngProgressButton: boolean | null = false;
  @Input()
  disabled: boolean | null = false;
  @Input()
  loadingColor: ThemePalette = 'primary';
  @Input()
  loadingDiameter = 20;

  private readonly matButtonBase: MatButton | MatIconButton | MatFabButton | MatMiniFabButton;
  private spinner: ComponentRef<MatProgressSpinner> | null = null;

  readonly spinnerMode: ProgressSpinnerMode = 'indeterminate';

  constructor(
    @Optional() private matButton: MatButton,
    @Optional() private matIconButton: MatIconButton,
    @Optional() private matFabButton: MatFabButton,
    @Optional() private matMiniFabButton: MatMiniFabButton,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2
  ) {
    this.matButtonBase = this.matButton ?? this.matIconButton ?? this.matFabButton ?? this.matMiniFabButton;
    if (!this.matButtonBase) {
      throw Error(
        `bngProgressButton currently only supports the following directives/components:
           mat-button
           mat-raised-button
           mat-stroked-button
           mat-flat-button
           mat-icon-button
           mat-fab
           mat-mini-fab

           If there is a new component that needs supported it will need to be injected in the constructor and added to matButtonBase.
           `
      );
    }
  }

  ngOnChanges(changes: NgChanges<ProgressButtonDirective>): void {
    if (!changes.bngProgressButton) {
      return;
    }

    if (changes.bngProgressButton.currentValue) {
      this.matButtonBase._elementRef.nativeElement.classList.add('button-loading');
      this.matButtonBase.disabled = true;
      this.createSpinner();
    } else if (!changes.bngProgressButton.firstChange) {
      this.matButtonBase._elementRef.nativeElement.classList.remove('button-loading');
      this.matButtonBase.disabled = Boolean(this.disabled);
      this.destroySpinner();
    }
  }

  private createSpinner(): void {
    if (this.spinner) {
      return;
    }

    this.spinner = this.viewContainerRef.createComponent(MatProgressSpinner);
    this.spinner.instance.color = this.loadingColor;
    this.spinner.instance.diameter = this.loadingDiameter;
    this.spinner.instance.mode = this.spinnerMode;

    this.renderer.appendChild(this.matButtonBase._elementRef.nativeElement, this.spinner.instance._elementRef.nativeElement);
  }

  private destroySpinner(): void {
    if (!this.spinner) {
      return;
    }

    this.spinner.destroy();
    this.spinner = null;
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'bng-loading-indicator',
  standalone: true,
  templateUrl: './bng-loading-indicator.component.html',
  styleUrl: './bng-loading-indicator.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingIndicatorComponent {
  @Input({ required: true }) loading: boolean = false;
}

<div *ngIf="imgSrc as img" class="flex items-center">
  <div *ngIf="label" class="mr-3">
    {{ label }}
  </div>
  <div
    [ngClass]="{
      'w-[18px]': eSize.SMALL === size,
      'w-[23px]': eSize.BASE === size,
      'w-[30px]': eSize.MEDIUM === size,
      'w-[50px]': eSize.LARGE === size
    }"
  >
    <img [src]="img" alt="Account Finalization Icon" />
  </div>
</div>

import { Component, Input } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg-2';

@Component({
  selector: 'lib-becks-svg',
  templateUrl: './becks-svg.component.html',
  styleUrls: ['./becks-svg.component.scss'],
  standalone: true,
  imports: [InlineSVGModule]
})
export class BecksSvgComponent {
  @Input() src: string;
}

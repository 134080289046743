// Format any string as a number,
// removing all symbols except those that are used in numbers.
// For example, multiplying a string number formatted as currency
// results in a NaN error because the dollar symbol and any commas

import { defaultTo } from 'app/shared/helpers/general.helper';
import { isNumber } from 'app/shared/pipes/ngx-pipes/helpers';

// are invalid numerical values for math operations.
export const formatAsNumber = (value: string | number | null | undefined): number => {
  return isNumber(value) ? Number(value) : value?.length ? Number(value.replace(/[^-?0-9.]/g, '')) : 0;
};

/**
 *
 * @param value Value to check if it is greater than 0
 * @returns True if the `value` is a number and greater than 0
 * @returns False if the `value` is '' | null | undefined
 */
export function greaterThanZero(value: string | number | null | undefined): boolean {
  return greaterThan(value, 0);
}

/**
 *
 * @param value Value to check
 * @param operand Value to check against
 * @returns True if the `value` is a number and greater than `operand`
 * @returns False if the `value` is '' | null | undefined
 */
export function greaterThan(value: string | number | null | undefined, operand: number): boolean {
  return _greaterThan(value, operand, false);
}

/**
 *
 * @param value Value to check
 * @param operand Value to check against
 * @returns True if the `value` is a number and greater than or equal to `operand`
 * @returns False if the `value` is '' | null | undefined
 */
export function greaterThanEqual(value: string | number | null | undefined, operand: number): boolean {
  return _greaterThan(value, operand, true);
}

/**
 *
 * @param value Value to check
 * @param operand Value to check against
 * @param inclusive Include `operand` in comparison
 * @returns True if the `value` is a number and greater than or equal to `operand` depending on `inclusive`
 * @returns False if the `value` is '' | null | undefined
 */
function _greaterThan(value: string | number | null | undefined, operand: number, inclusive: boolean): boolean {
  if (!isNumber(value)) {
    return false;
  }
  return inclusive ? value >= operand : value > operand;
}

/**
 *
 * @param value Value to check if it is less than 0
 * @returns True if the `value` is a number and less than 0
 * @returns False if the `value` is '' | null | undefined
 */
export function lessThanZero(value: string | number | null | undefined): boolean {
  return lessThan(value, 0);
}

/**
 *
 * @param value Value to check
 * @param operand Value to check against
 * @returns True if the `value` is a number and less than `operand`
 * @returns False if the `value` is '' | null | undefined
 */
export function lessThan(value: string | number | null | undefined, operand: number): boolean {
  return _lessThan(value, operand, false);
}

/**
 *
 * @param value Value to check
 * @param operand Value to check against
 * @returns True if the `value` is a number and less than or equal to `operand`
 * @returns False if the `value` is '' | null | undefined
 */
export function lessThanEqual(value: string | number | null | undefined, operand: number): boolean {
  return _lessThan(value, operand, true);
}

/**
 *
 * @param value Value to check
 * @param operand Value to check against
 * @param inclusive Include `operand` in comparison
 * @returns True if the `value` is a number and less than or equal to `operand` depending on `inclusive`
 * @returns False if the `value` is '' | null | undefined
 */
function _lessThan(value: string | number | null | undefined, operand: number, inclusive: boolean): boolean {
  if (!isNumber(value)) {
    return false;
  }
  return inclusive ? value <= operand : value < operand;
}

/**
 * @param value Value to check
 * @returns The value if it is not `null`, `undefined` or `NaN`, otherwise 0
 */
export const defaultToZero = defaultTo(0);

export default {
  appCode: 'SALES',
  production: true,
  name: 'qa',
  apiUrl: 'https://bossapi.app-qa.beckshybrids.com',
  socket: {
    url: 'https://bossapi.app-qa.beckshybrids.com',
    path: ''
  },
  notifications: {
    apiUrl: 'https://notificationsapi.app-qa.beckshybrids.com',
    socket: {
      url: 'https://notificationsapi.app-qa.beckshybrids.com',
      path: ''
    }
  },
  authApiUrl: 'https://authn.app-qa.beckshybrids.com/api',
  bossUrl: 'https://qaboss.beckshybrids.com',
  legacyBossUrl: 'https://qaoldboss.beckshybrids.com',
  prodSuiteUrl: 'https://qaprodsuite.beckshybrids.com',
  authCookieName: 'becks-auth-qa',
  okta: {
    clientId: '0oa1rd8fzf2TaOtfQ0h8',
    externalOrgUrl: 'https://mybecks.beckshybrids.com',
    internalOrgUrl: 'https://login.beckshybrids.com'
  },
  cmsAPIKey: '1843faf87aa3de5c561039415cec55237f13893e',
  gMapsAPIKey: 'AIzaSyCuY5P8pKuqJ-ufHbpnwncNvu-VxkM09tQ',
  smartyStreetsAPIUrl: 'api.smartystreets.com',
  smartyStreetsAPIKey: '35473282022018997',
  authTokenName: 'x-auth-token',
  rollbarAccessToken: 'e470168ae1e74c91b3348e0f7b2d6817',
  reactClientUrl: 'https://qaboss.beckshybrids.com',
  flagsmithKey: '2wPk6x59ASj7TNnctTxWsL',
  gMapsMapID: 'ea788fc3e02165b6'
};

import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { IFamilyGroupWithIcons } from '../../models/family.interface';
import { BecksSvgComponent } from '../../../becks-svg/components/becks-svg/becks-svg.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { NgClass, NgIf, NgFor } from '@angular/common';

@Component({
  selector: 'lib-becks-family-toggle',
  templateUrl: './becks-family-toggle.component.html',
  styleUrls: ['./becks-family-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgClass, NgIf, NgFor, MatIcon, MatTooltip, BecksSvgComponent]
})
export class BecksFamilyToggleComponent {
  @Input() familyGroups: IFamilyGroupWithIcons[] | null;
  @Input() selectedFamilyGroup: IFamilyGroupWithIcons | null | undefined;
  @Input() isReadOnly?: boolean = false;
  @Input() multipleFamilySelect: boolean = false;
  @Input() selectedFamilyCodes: string[] | null;

  @Output() onFamilyGroupSelected: EventEmitter<IFamilyGroupWithIcons> = new EventEmitter();

  handleFamilyGroupSelected(selectedFamilyGroup: IFamilyGroupWithIcons, event: MouseEvent) {
    // don't trigger an event when display is read-only
    if (this.isReadOnly) {
      event.preventDefault();
      return;
    }

    this.onFamilyGroupSelected.emit(selectedFamilyGroup);
  }
}

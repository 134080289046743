import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { SalesLookupService } from 'app/shared/services/sales-lookup.service';

import * as SalesLookupActions from '../actions/sales-lookup.actions';

@Injectable()
export class SalesLookupEffects {
  constructor(
    private _actions$: Actions,
    private _salesLookupService: SalesLookupService
  ) {}

  /** Load Dealers */
  loadDealers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SalesLookupActions.loadDealers),
      switchMap(() =>
        this._salesLookupService.fetchDealers().pipe(
          map((dealers) => SalesLookupActions.loadDealersSuccess({ dealers })),
          catchError((error) => of(SalesLookupActions.loadDealersFailure({ error })))
        )
      )
    )
  );
}

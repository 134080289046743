import { TitleCasePipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AccountFinalizationModalService } from 'app/shared/services/account-finalization-modal.service';
import { AccountFinalizationService } from 'app/shared/services/account-finalization.service';
import { AcknowledgementsService } from 'app/shared/services/acknowledgements.service';
import { ModalService } from './components/confirmation-modal/confirmation-modal.service';
import { ProgressSpinnerService } from './components/progress-spinner/progress-spinner.service';
import { CustomerSearchService } from './customer-search/customer-search.service';
import { ApiService } from './services/api.service';
import { GlobalService } from './services/global.service';
import { LoadingService } from './services/loading.service';
import { ProductsService } from './services/products.service';
import { SalesLookupService } from './services/sales-lookup.service';
import { SnackbarService } from './services/snackbar.service';
import { UserService } from './services/user.service';

@NgModule()
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ApiService,
        SnackbarService,
        UserService,
        ModalService,
        LoadingService,
        ProgressSpinnerService,
        CustomerSearchService,
        SalesLookupService,
        GlobalService,
        ProductsService,
        AcknowledgementsService,
        AccountFinalizationService,
        AccountFinalizationModalService,
        // TitleCasePipe is used by snackbar.service
        TitleCasePipe
      ]
    };
  }
}

interface IBreakpointsObject {
  [breakpointName: string]: IBreakpoint;
}

interface IBreakpoint {
  id: Breakpoints;
  mediaQuery: string;
  applied: boolean;
}

enum Breakpoints {
  XXL = 'xxl',
  XXL_UP = 'xxlUp',
  XXL_DOWN = 'xxlDown',
  XL = 'xl',
  XL_UP = 'xlUp',
  XL_DOWN = 'xlDown',
  LG = 'lg',
  LG_UP = 'lgUp',
  LG_DOWN = 'lgDown',
  MD = 'md',
  MD_LANDSCAPE = 'mdLandscape',
  MD_UP = 'mdUp',
  MD_DOWN = 'mdDown',
  SM = 'sm',
  SM_LANDSCAPE = 'smLandscape',
  SM_UP = 'smUp',
  SM_DOWN = 'smDown',
  XS = 'xs',
  XS_UP = 'xsUp',
  XS_DOWN = 'xsDown',
  XS_LANDSCAPE = 'xsLandscape'
}

enum MediaQueries {
  XSMALL = '(max-width: 599.98px)',
  SMALL = '(min-width: 600px) and (max-width: 959.98px)',
  MEDIUM = '(min-width: 960px) and (max-width: 1279.98px)',
  LARGE = '(min-width: 1280px) and (max-width: 1919.98px)',
  XLARGE = '(min-width: 1920px)'
}

export { Breakpoints, IBreakpoint, IBreakpointsObject, MediaQueries };

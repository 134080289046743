/**
 * Load Action Guard Factory is used to generate an Observable<boolean> to be used with a data-loading guard.
 * This observable completes with a `true` value when the data has been loaded
 * or `false` when an error occurred while loading data.
 *
 * There are a couple of key features:
 *
 * 1. Non-blocking route activation: setting `isBlocking: false` will allow for the route to activate without having to wait for the data to be completely loaded
 *
 * 2. User roles: by proving a `features` array, the data will only be fetched if the user has one of the roles in the list
 *
 * Notes on role-based loading:
 *
 * 1. Best use of this guard is by combining the `features` list
 *    with `isBlocking: false`. This way the data will be loaded, if the user has the given role, and there is no waiting.
 *
 * 2. When using non-blocking role-based loading, the usage of the isLoading flag is advised when the data
 *    that is being loading will immediately be used on the page.
 *
 * 3. !IMPORTANT! If using `features` with `isBlocking: true`, be aware that if the user does not have the role, the page will never load
 *
 * 4. !IMPORTANT! If the feature code controls the whole page you are activating, the `authorizationGuard` guard would be the best used for the route.
 *
 * @usageNotes
 *
 * ```ts
 * // basic usage
 * export const overrideReasonsGuard: CanActivateFn = (
 *  route: ActivatedRouteSnapshot,
 *  state: RouterStateSnapshot
 * ) => {
 *   const guardFactory = inject(LoadActionGuardFactoryService);
 *
 *   return guardFactory.canAdvance({
 *     loadAction: fetchOverrideReasons(),
 *     isLoadedSelector: selectOverrideReasonsLoaded,
 *     hasErrorSelector: selectOverrideReasonsErred
 *   });
 * }
 * ````
 *
 * ```ts
 * // non-blocking load
 * export const overrideReasonsGuard: CanActivateFn = (
 *  route: ActivatedRouteSnapshot,
 *  state: RouterStateSnapshot
 * ) => {
 *   const guardFactory = inject(LoadActionGuardFactoryService);
 *
 *   return guardFactory.canAdvance({
 *     loadAction: fetchOverrideReasons(),
 *     isLoadedSelector: selectOverrideReasonsLoaded,
 *     hasErrorSelector: selectOverrideReasonsErred,
 *     isBlocking: false
 *   });
 * }
 * ````
 *
 * ```ts
 * // role-based loading
 * export const overrideReasonsGuard: CanActivateFn = (
 *  route: ActivatedRouteSnapshot,
 *  state: RouterStateSnapshot
 * ) => {
 *   const guardFactory = inject(LoadActionGuardFactoryService);
 *
 *   return guardFactory.canAdvance({
 *     loadAction: fetchOverrideReasons(),
 *     isLoadedSelector: selectOverrideReasonsLoaded,
 *     hasErrorSelector: selectOverrideReasonsErred,
 *     isBlocking: false,
 *     features: ['CODE']
 *   });
 * }
 * ````
 */

import { Injectable } from '@angular/core';
import { Action, MemoizedSelector, Store } from '@ngrx/store';
import { Observable, of, race, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { getUserHasFeatureCodes, getUserLoaded } from 'app/store/selectors';

@Injectable()
export class LoadActionGuardFactoryService {
  constructor(private _store: Store) {}

  canAdvance({
    loadAction,
    isLoadedSelector,
    hasErrorSelector,
    isBlocking = true,
    features = []
  }: {
    loadAction: Action;
    isLoadedSelector: MemoizedSelector<object, boolean>;
    hasErrorSelector: MemoizedSelector<object, boolean>;
    isBlocking?: boolean;
    features?: string[];
  }): Observable<boolean> {
    return this._checkStore(loadAction, isLoadedSelector, hasErrorSelector, isBlocking, features).pipe(
      // returns observable of true if things have gone correctly
      switchMap(() => of(true)),
      // else returns observable of false if things have gone awry
      catchError(() => of(false))
    );
  }

  private _checkStore(
    loadAction: Action,
    isLoadedSelector: MemoizedSelector<object, boolean>,
    hasErrorSelector: MemoizedSelector<object, boolean>,
    isBlocking: boolean,
    features: string[]
  ): Observable<boolean> {
    return race(
      this._waitForSecurityToLoad(features).pipe(
        switchMap((hasFeatures) => this._waitForCollectionToLoad(loadAction, isLoadedSelector, isBlocking, hasFeatures)),
        take(1)
      ),
      this._waitForErrorToHappen(hasErrorSelector)
    );
  }

  private _waitForCollectionToLoad(
    action: Action,
    selector: MemoizedSelector<object, boolean>,
    isBlocking: boolean,
    hasFeatures: boolean
  ): Observable<boolean> {
    return this._store.select(selector).pipe(
      tap((loaded: boolean) => {
        if (!loaded && hasFeatures) {
          this._store.dispatch(action);
        }
      }),
      map((loaded: boolean) => (isBlocking ? loaded : true)), // do not wait on non-blocking loads
      filter((loaded: boolean) => loaded),
      // after loaded has become true...
      take(1)
    );
  }

  private _waitForErrorToHappen(selector: MemoizedSelector<object, boolean>): Observable<boolean> {
    return this._store.select(selector).pipe(
      distinctUntilChanged(),
      filter((erred: boolean) => erred),
      // after erred has become true return false to avoid page to keep going
      switchMap(() => throwError(() => new Error('Fail to load data')))
    );
  }

  private _waitForSecurityToLoad(features: string[]): Observable<boolean> {
    return this._store.select(getUserLoaded).pipe(
      // only pass when user is authenticated
      filter((isUserLoaded) => isUserLoaded),
      // perform necessary loads
      switchMap(() => this._checkPermissions(features))
    );
  }

  private _checkPermissions(features: string[]): Observable<boolean> {
    // no features are required
    if (!Array.isArray(features) || features.length === 0) {
      return of(true);
    }

    return this._store.select(getUserHasFeatureCodes(features));
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SystemInfoState, systemInfoStateKey } from 'app/store/reducers/system-info.reducer';
import { Breakpoints } from '../../layout/models/breakpoints.interface';

export const getSystemInfoState = createFeatureSelector<SystemInfoState>(systemInfoStateKey);

export const getSystemInfoBreakpointEntities = createSelector(getSystemInfoState, (state: SystemInfoState) => state.breakpointEntities);

// DERIVED SELECTORS
export const getSystemInfoBreakpointActiveByID = (props: { breakpoint: Breakpoints }) =>
  createSelector(getSystemInfoBreakpointEntities, (entities) => {
    return entities[props.breakpoint] ? entities[props.breakpoint].applied : false;
  });

// returns true if any of the breakpoints from the array are active
export const getSystemInfoBreakpointActiveFromArray = (props: { breakpoints: Breakpoints[] }) =>
  createSelector(getSystemInfoBreakpointEntities, (entities) => {
    let hasActiveBreakpoint: boolean = false;

    props.breakpoints.forEach((breakpoint) => {
      if (entities[breakpoint] && entities[breakpoint].applied && !hasActiveBreakpoint) {
        hasActiveBreakpoint = true;
      }
    });
    return hasActiveBreakpoint;
  });

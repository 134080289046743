import { Action } from '@ngrx/store';
import { IBreakpoint } from 'app/layout/models/breakpoints.interface';

export enum SystemInfoActionTypes {
  SetBreakpointEntities = '[Global] Set Breakpoint Entities'
}

export class SetBreakpointEntities implements Action {
  readonly type = SystemInfoActionTypes.SetBreakpointEntities;
  constructor(public payload: Record<string, IBreakpoint>) {}
}

export type SystemInfoAction = SetBreakpointEntities;

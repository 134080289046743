<div class="logout">
  <div class="logout__container">
    <div class="logout__container-logo"></div>
    <h3 class="logout__container-title">You are being logged out...</h3>

    <p class="logout__container-content">
      If your browser does not redirect you back, please
      <a [routerLink]="'/'">click here</a>
      to continue.
    </p>
  </div>
</div>

import { IBreakpointsObject } from '../../layout/models/breakpoints.interface';
import { SystemInfoAction, SystemInfoActionTypes } from '../actions';

// REFERENCE:  https://github.com/palantir/tslint/issues/1063
export interface SystemInfoState {
  breakpointEntities: IBreakpointsObject;
}

const initialState: SystemInfoState = {
  breakpointEntities: {}
};

export const systemInfoStateKey = 'systemInfo';
export function systemInfoReducer(state = initialState, action: SystemInfoAction): SystemInfoState {
  switch (action.type) {
    case SystemInfoActionTypes.SetBreakpointEntities: {
      const breakpointEntities = action.payload;
      return {
        ...state,
        breakpointEntities
      };
    }

    default:
      return state;
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AcknowledgementCode, IAcknowledgement } from 'app/shared/models/acknowledgement.interface';
import { ApiService } from 'app/shared/services/api.service';

@Injectable()
export class AcknowledgementsService {
  constructor(private _apiService: ApiService) {}

  public saveAcknowledgement(code: AcknowledgementCode): Observable<IAcknowledgement> {
    return this._apiService.post<IAcknowledgement>(`/acknowledgements`, { code });
  }
}
